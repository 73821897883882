import React from "react";
import { Heading, Icon, List } from "@dc/react-components";
import GridList from "./GridList";
import PropTypes from "prop-types";
import { Svg } from "../../../../ws10";

const FooterGrid = (props) => {
  const { heading } = props;

  return (
    <>
      <input type="radio" name="footer-menu" />
      <div className="footer__menu--header">
        <Heading level={4}>{heading}</Heading>
        <div className="footer__menu--icon">
          <Icon Source={Svg.ChevronDown} size="small" />
        </div>
      </div>
      <List component="ul" isReset className="footer__menu--list">
        <GridList {...props} />
      </List>
    </>
  );
};

FooterGrid.propTypes = {
  props: PropTypes.object,
  heading: PropTypes.string
};

export default FooterGrid;
