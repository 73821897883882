import React from "react";
import PropTypes from "prop-types";
import { Heading, Icon } from "@dc/react-components";
import CardDcl from "./CardDcl.web";

import "./Card.scss";

function Card({
  bgcolor,
  alignment,
  icon,
  hidden,
  title,
  edit,
  type,
  handleVisibilityToggle,
  onClickHandler,
  bgImg,
  children, 
  height,
  fontColor, 
  submenuBanner
}) {
  let svgIcon;
  if (icon) {
    svgIcon = <Icon Source={icon} size="small" />;
  }

  return (
    <CardDcl
      bgcolor={bgcolor}
      alignment={alignment}
      hidden={hidden}
      edit={edit}
      type={type}
      handleVisibilityToggle={handleVisibilityToggle}
      onClickHandler={onClickHandler}
      bgImg={bgImg}
      height={height} 
      submenuBanner={submenuBanner} 
      >
      <Heading level={5} component="h3" className={!icon ? "no-icon" : ""}>
        {svgIcon}
        {title}
      </Heading>
      {children}
    </CardDcl>
  );
}

Card.propTypes = {
  bgcolor: PropTypes.string,
  alignment: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hidden: PropTypes.bool,
  title: PropTypes.string,
  edit: PropTypes.bool,
  type: PropTypes.string,
  handleVisibilityToggle: PropTypes.func,
  onClickHandler: PropTypes.func,
  children: PropTypes.node
};

export default Card;
