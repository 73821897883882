import React from "react";
import PropTypes from "prop-types";
import { Heading } from "@dc/react-components";
import ProgressBar from "../ProgressBar";

const CardData = ({ value, unit, total, percentage, date }) => {
  let renderedDate;
  if (date) {
    renderedDate = new Date(date).toLocaleString("en-GB", { month: "short", day: "2-digit" });
  }

  return (
    <div className="card__info">
      <Heading level={5} component="h5">
        <span>{value}</span>
        <span className="unit">{unit}</span> left of {total}
      </Heading>
      <ProgressBar percent={percentage} color="primary" />
      {renderedDate && <p>Renews on&nbsp;{renderedDate}</p>}
    </div>
  );
};

CardData.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  unit: PropTypes.string,
  total: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  date: PropTypes.string
};

export default CardData;
