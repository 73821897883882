import classnames from "classnames";
import * as React from "react";
import PropTypes from "prop-types";

// type Props = {
//   /** Visually hide the label. This will keep the text for accessibility users. */
//   hideLabel?: boolean,
//   /** The text preceding the switch status. */
//   title?: string,
//   /** The text displayed after the title when the switch is off. */
//   offLabel?: string,
//   /** The text displayed after the title when the switch is on. */
//   onLabel?: string,
//   /** Specifies CSS classes to be added to the root .toggle-switch container. */
//   classNames?: string,
//   /** Dictates it's value */
//   value?: boolean,
//   /** The function called when the switch is toggled */
//   onChange: boolean => void,
//   /** Disables the Switch */
//   isDisabled?: boolean,
//   /** Changes the return type to be an input event */
//   returnEvent?: boolean
// };

const ToggleSwitch = ({
  classNames,
  hideLabel = false,
  title,
  offLabel = "Off",
  onLabel = "On",
  value,
  onChange,
  isDisabled = false,
  returnEvent,
}) => (
  <div className={classnames("toggle-switch", classNames)}>
    <label className="toggle-switch__trigger">
      <input
        onChange={onChange ? (e) => onChange(returnEvent ? e : e.target.checked) : null}
        className="toggle-switch__input"
        type="checkbox"
        checked={value}
        disabled={isDisabled}
      />
      <span
        className={classnames("toggle-switch__title", {
          "toggle-switch__title--hide": hideLabel,
        })}>
        {title}
        <strong className="toggle-switch__feedback toggle-switch__feedback--on">{onLabel}</strong>
        <strong className="toggle-switch__feedback toggle-switch__feedback--off">{offLabel}</strong>
      </span>
      <div
        className={classnames("toggle-switch__indicator-wrapper", {
          "form__input--disabled": isDisabled,
        })}
        style={{ userSelect: "none" }}>
        <span className="toggle-switch__indicator">
          <span className="toggle-switch__indication">&nbsp;</span>
        </span>
      </div>
    </label>
  </div>
);

ToggleSwitch.propTypes = {
  classNames: PropTypes.string,
  hideLabel: PropTypes.bool,
  title: PropTypes.string,
  offLabel: PropTypes.string,
  onLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  returnEvent: PropTypes.bool,
};

export default ToggleSwitch;
