import React, { useState } from "react";
import PropTypes from "prop-types";
import { Svg } from "../../../ws10";
import Form from "../Form";

// TODO: unit test the logic in this file

/**
 * Function called onfocus od the inputs of the form
 * It adds the necessary css classes to the elements
 * @param {*} e - event fired containing the target element of that event
 */
function onFocusInput(e) {
  const inputElement = e.target;
  const label = inputElement.parentElement.parentElement;
  const floatingLabel = inputElement.parentElement.previousSibling;
  if (inputElement.value === "") {
    label.classList.add("on-focus");
  }
  floatingLabel.classList.add("focus");
}

/**
 * Function called onfocus od the inputs of the form
 * It removes the necessary css classes to the elements
 * @param {*} e - event fired containing the target element of that event
 */
function handleInputFocusOut(e) {
  const input = e.target;
  const label = input.parentElement.parentElement;
  const floatingLabel = input.parentElement.previousSibling;
  if (input.value === "") {
    label.classList.remove("on-focus");
  }
  floatingLabel.classList.remove("focus");
}

function LoginFormUser({ isFormFieldOk, fillUsername, id }) {
  const [username, setUsername] = useState("");

  const handleInputUsername = (e) => {
    const username = e.target.value;
    setUsername(username);

    //check if username exists and sends username to Login if so
    username.length > 0 && isFormFieldOk ? fillUsername(true, username) : fillUsername(false, null);
  };

  /**
   * Handles the event fired by the click on the login button
   * Calls the login service when the user clicks in the button.
   * Handles the response of the service.
   * If the response is  200 ok, redirects the user to the Bills page
   * If the response is not 200, prints the error to the console.s
   * @param e - click event
   */

  const displayHint = !isFormFieldOk ? "Please enter a valid username" : null;

  return (
    <>
      <Form.Row spaceTop="leading">
        <Form.Input
          label="Username"
          name="username"
          onChange={(event) => handleInputUsername(event)}
          onFocus={(event) => onFocusInput(event)}
          onBlur={(event) => handleInputFocusOut(event)}
          value={username}
          isRequired
          isIcon={!isFormFieldOk}
          isErrorLabel={!isFormFieldOk}
          iconSource={Svg.Warning}
          iconPosition="right"
          hint={displayHint}
          id={"sign-in-username-field-" + id}
        />
      </Form.Row>
    </>
  );
}

LoginFormUser.propTypes = {
  isFormFieldOk: PropTypes.bool.isRequired,
  fillUsername: PropTypes.func,
  id: PropTypes.string
};

export default LoginFormUser;
