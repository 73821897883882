/* @flow */

import * as React from "react";

type Context = {
  hasHorizontalGutter: boolean,
  hasVerticalGutter: boolean
};

const GridContext = React.createContext<Context>({
  hasHorizontalGutter: false,
  hasVerticalGutter: false
});

export default GridContext;
