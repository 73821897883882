import React, { useContext } from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import AppContext from "../../../AppContext";

const Help = () => {
  const { toggleSubmenu, isMobile } = useContext(AppContext);
  return (
    <div className="submenu__content--list submenu__content--help">
      {isMobile && (
        <>
          <a className="back-button" onClick={() => toggleSubmenu(true)}>
            <Icon Source={Svg.ArrowLeft} />
          </a>
        </>
      )}
      <div className="icon">
        <img src="https://v1.vodafone.it/portal/resources/media/Images/TOBi/Landing/dsk/tobi.png" alt="TOBi logo" />
      </div>
      <h2 className="heading heading--2 heading--bold">Hello I am TOBi</h2>
      <p className="heading heading--4">How can I help you today?</p>
      <button type="button" className="button button--primary">
        Ask me a question
      </button>
      <a href="#">
        My conversations
        <div className="badge">
          <span>3</span>
        </div>
      </a>
      <h4 className="heading heading--4 heading--bold">Help topics</h4>
      <a href="#">See all help</a>
      <ul>
        <li>Costs charges</li>
        <li>Support</li>
        <li>Broadband</li>
        <li>Customer care</li>
      </ul>
    </div>
  );
};

export default Help;
