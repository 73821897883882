import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
// Components
import Menu from "./components/web/Menu";
import Footer from "./components/web/Footer/Footer";
import PrivateRoute from "./components/web/PrivateRoute";
import AppContext from "./AppContext";
import { getUserAuthData } from "./utils";
import Loading from "./components/web/Loading";
import Spinner from "./components/web/Spinner";
import { createBrowserHistory } from "history";
import { authServices } from "@dc/digital-channels-sdk";
import "../src/ws10/resources/assets/styles/main.scss";
import PropTypes from "prop-types";
import BroadbandAvailability from "./pages/BroadbandAvailability";
import TestPage from "./pages/testPage";

// Components
const Homepage = lazy(() => import(/* webpackChunkName: "Homepage" */ "./pages/homepage"));
const MyOffers = lazy(() => import(/* webpackChunkName: "MyOffers" */ "./pages/MyOffers"));
const MyOffersDetails = lazy(() => import(/* webpackChunkName: "MyOffersDetails" */ "./pages/MyOffersDetails"));
const Bills = lazy(() => import(/* webpackChunkName: "Bills" */ "./pages/bills"));
const BillDetails = lazy(() => import(/* webpackChunkName: "BillDetails" */ "./pages/bills/BillDetails"));
const ProductDetails = lazy(() => import(/* webpackChunkName: "ProductDetails" */ "./pages/ProductDetails"));
const EShop = lazy(() => import(/* webpackChunkName: "EShop" */ "./pages/eshop"));
const Cart = lazy(() => import(/* webpackChunkName: "Cart" */ "./pages/Cart"));
const Checkout = lazy(() => import(/* webpackChunkName: "Checkout" */ "./pages/Checkout"));
const ThankYou = lazy(() => import(/* webpackChunkName: "ThankYou" */ "./pages/ThankYou"));
const SearchResults = lazy(() => import(/* webpaclChunkName: 'SearchResults' */ "./pages/SearchResults"));

const mobileWidth = "1000px";

window.receiveATOffers = function receiveATOffers(offers) {
  console.log(offers);
  //TODO maybe implement the observer pattern and pass a registration method in the AppContext
};

function getProductInBasketIndex(productsList, productId) {
  return productsList.indexOf(productsList.find((product) => product.id === productId));
}

// TODO maybe reconsider this approach
function Main({ children }) {
  return <div style={{ minHeight: "100vh" }}>{children}</div>;
}

Main.propTypes = {
  children: PropTypes.node
};

function App() {
  const history = useHistory();
  const location = useLocation();
  let ampLink;

  const componentWillMount = useRef(true);
  if (componentWillMount.current && location.pathname.includes("galaxy_s21_ultra_5g")) {
    ampLink = document.createElement("link");
    ampLink.setAttribute("rel", "amphtml");
    ampLink.setAttribute("href", window.location.origin + "/amp/shop/phones/galaxy_s21_ultra_5g.html");
    document.getElementsByTagName("head")[0].appendChild(ampLink);

    componentWillMount.current = false;
  }

  const userAuthData = getUserAuthData();
  const [isLoggedIn, setIsLoggedIn] = useState(userAuthData !== null);
  const [username, setUsername] = useState(userAuthData ? userAuthData.user_name : undefined);
  const [isMobile, setIsMobile] = useState(false);
  const [isSubmenuDisplayed, setIsSubmenuDisplayed] = useState(false);
  const [isSublevelDisplayed, setIsSublevelDisplayed] = useState(false);
  const [isMobileMenuContentDisplayed, setIsMobileMenuContentDisplayed] = useState(false);
  const [isShopMenuDisplayed, setShopMenuDisplayed] = useState(false);
  const [isHelpMenuDisplayed, setIsHelpMenuDisplayed] = useState(false);
  const [isMyVodafoneMenuDisplayed, setIsMyVodafoneMenuDisplayed] = useState(false);
  const [isMobilesAndTabletsDisplayed, setIsMobilesAndTabletsDisplayed] = useState(false);
  const [isBroadbandDisplayed, setIsBroadbandDisplayed] = useState(false);
  const [isSimOnlyDisplayed, setIsSimOnlyDisplayed] = useState(false);
  const [isTVDisplayed, setIsTVDisplayed] = useState(false);
  const [isWearablesDisplayed, setIsWearablesDisplayed] = useState(false);
  const [isBundlesAndOffersDisplayed, setIsBundlesAndOffersDisplayed] = useState(false);
  const [isMyProductsDisplayed, setIsMyProductsDisplayed] = useState(false);
  const [isOverlayDisplayed, setIsOverlayDisplayed] = useState(false);
  const [productsInBasket, setProductsInBasket] = useState([]);
  const [totalCardSum, setTotalCartSum] = useState(0);
  const [clientOrder, setClientOrder] = useState();
  const [isProductEdit, setIsProductEdit] = useState(false);
  const [productToBeEdited, setProductToBeEdited] = useState();
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [isSearchDisplayed, setIsSearchDisplayed] = useState(false);

  useEffect(() => {
    const windowSizeHandler = (e) => {
      setIsMobile(e.matches);
    };

    const mobileMatchMedia = window.matchMedia(`(max-width: ${mobileWidth})`);
    mobileMatchMedia.addEventListener("change", windowSizeHandler);
    setIsMobile(mobileMatchMedia.matches);

    return function cleanup() {
      mobileMatchMedia.removeEventListener("change", windowSizeHandler);
    };
  }, []);

  useEffect(() => {
    calculateTotalBasketSum();
  }, [productsInBasket]);

  useEffect(() => {
    if (isOverlayDisplayed) {
      toggleOverlay(false);
    }
  }, [location]);

  const handleLogin = (username) => {
    setIsLoggedIn(true);
    setUsername(username);
  };

  const handleLogout = async () => {
    try {
      const response = await authServices.logout();

      if (response) {
        setIsLoggedIn(false);
        setUsername(undefined);
        history.push("/");
      }
    } catch (err) {
      throw new Error(err);
    }
  };

  const toggleSubmenu = (isDisplayed, contentName) => {
    if (isMobile) {
      setIsSublevelDisplayed(false);
      setIsSubmenuDisplayed(isDisplayed);
      setShopMenuDisplayed(false);
      setIsMyProductsDisplayed(false);
      resetAllSublevels();
      setIsSearchDisplayed(false);
    } else {
      setIsSublevelDisplayed(false);
      resetAllSublevels();
      setIsSearchDisplayed(false);
    }

    setIsSubmenuDisplayed(isDisplayed);

    if (isDisplayed) {
      if (contentName === "Menu" || contentName === undefined) {
        setIsMobileMenuContentDisplayed(isDisplayed);
        setShopMenuDisplayed(false);
        setIsHelpMenuDisplayed(false);
        setIsMyVodafoneMenuDisplayed(false);
      } else if (contentName === "Shop") {
        setShopMenuDisplayed(isDisplayed);
        setIsMobileMenuContentDisplayed(false);
        setIsHelpMenuDisplayed(false);
        setIsMyVodafoneMenuDisplayed(false);
      } else if (contentName === "Help") {
        setIsHelpMenuDisplayed(isDisplayed);
        setIsMobileMenuContentDisplayed(false);
        setShopMenuDisplayed(false);
        setIsMyVodafoneMenuDisplayed(false);
      } else if (contentName === "My Vodafone") {
        setIsMyVodafoneMenuDisplayed(isDisplayed);
        setIsMobileMenuContentDisplayed(false);
        setShopMenuDisplayed(false);
        setIsHelpMenuDisplayed(false);
      }
    } else {
      setShopMenuDisplayed(isDisplayed);
      setIsHelpMenuDisplayed(isDisplayed);
      setIsMyVodafoneMenuDisplayed(isDisplayed);
    }
  };

  const resetAllLevels = () => {
    setIsSublevelDisplayed(false);
    setIsSubmenuDisplayed(false);
    setShopMenuDisplayed(false);
    setIsHelpMenuDisplayed(false);
    setIsMyVodafoneMenuDisplayed(false);
  };

  const resetAllSublevels = () => {
    setIsSublevelDisplayed(false);
    setIsMyProductsDisplayed(false);
    setIsMobilesAndTabletsDisplayed(false);
    setIsBroadbandDisplayed(false);
    setIsSimOnlyDisplayed(false);
    setIsTVDisplayed(false);
    setIsWearablesDisplayed(false);
    setIsBundlesAndOffersDisplayed(false);
  };

  const displaySublevelContent = (contentName) => {
    switch (contentName) {
      case "Mobiles and Tablets":
        setIsMobilesAndTabletsDisplayed(true);
        setIsBroadbandDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsTVDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "Broadband":
        setIsBroadbandDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsTVDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "Sim Only":
        setIsSimOnlyDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsBroadbandDisplayed(false);
        setIsTVDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "TV":
        setIsTVDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsBroadbandDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "Wearables":
        setIsWearablesDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsBroadbandDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsTVDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "Bundles and Offers":
        setIsBundlesAndOffersDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsBroadbandDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsTVDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsMyProductsDisplayed(false);
        break;
      case "My Products":
        setIsMyProductsDisplayed(true);
        setIsMobilesAndTabletsDisplayed(false);
        setIsBroadbandDisplayed(false);
        setIsSimOnlyDisplayed(false);
        setIsTVDisplayed(false);
        setIsWearablesDisplayed(false);
        setIsBundlesAndOffersDisplayed(false);
        break;
      default:
        break;
    }
  };

  const toggleSubmenuSublevel = (isDisplayed, contentName) => {
    if (!isSublevelDisplayed) {
      setIsSublevelDisplayed(isDisplayed);
      displaySublevelContent(contentName);
    }
    if (isSublevelDisplayed && isMobile) {
      resetAllSublevels();
    } else {
      if (isDisplayed == true) {
        displaySublevelContent(contentName);
      } else {
        resetAllSublevels();
      }
    }
  };

  const toggleMyProducts = (isDisplayed) => {
    if (typeof isDisplayed === "object") {
      setIsMyProductsDisplayed((prev) => !prev);
    } else {
      setIsMyProductsDisplayed(isDisplayed);
    }
  };

  // Toggle Search form
  const toggleSearch = () => {
    setIsSearchDisplayed((prev) => !prev);
    resetAllLevels();
    resetAllSublevels();
  };

  // Overlay/modal functionality
  const toggleOverlay = (isDisplayed) => {
    setIsOverlayDisplayed(isDisplayed);
  };

  if (isSubmenuDisplayed || isOverlayDisplayed) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  // Cart functionality
  const updateProductQuantityFromButtons = (action, productId) => {
    const productIndex = getProductInBasketIndex(productsInBasket, productId);
    let updatedProductsBasket = [...productsInBasket];

    if (action === "increase") {
      updatedProductsBasket[productIndex].quantity = updatedProductsBasket[productIndex].quantity + 1;
      setProductsInBasket(updatedProductsBasket);
      calculateTotalBasketSum();
    } else {
      if (updatedProductsBasket[productIndex].quantity > 0) {
        updatedProductsBasket[productIndex].quantity = updatedProductsBasket[productIndex].quantity - 1;
        setProductsInBasket(updatedProductsBasket);
        calculateTotalBasketSum();
      }
    }
  };

  const addProductToBasket = (newProduct) => {
    let updatedProductsBasket = [...productsInBasket];
    updatedProductsBasket.push(newProduct);
    setProductsInBasket(updatedProductsBasket);
  };

  const removeProductFromBasket = (productId) => {
    const updatedProductsBasket = productsInBasket.filter((product) => product.id !== productId);
    setProductsInBasket(updatedProductsBasket);
  };

  const checkIfProductIsInBasket = (productId) => {
    const productInBasket = productsInBasket.find((product) => product.id === productId);
    return !!productInBasket;
  };

  const calculateTotalBasketSum = () => {
    if (productsInBasket.length > 0) {
      const priceSum = productsInBasket.reduce((a, b) => {
        return a + b.price * b.quantity;
      }, 0);
      setTotalCartSum(priceSum);
    } else {
      setTotalCartSum(0);
    }
  };

  const calculateProductQuantityInBasket = () => {
    if (productsInBasket.length > 0) {
      return productsInBasket.reduce((a, b) => {
        return a + b.quantity;
      }, 0);
    } else {
      return 0;
    }
  };

  const clearBasket = () => {
    while (productsInBasket.length) {
      productsInBasket.pop();
    }
  };

  const confirmOrder = (order) => {
    setClientOrder(order);
  };

  const resetClientOrder = () => {
    setClientOrder();
  };

  // Edit product in basket
  const toggleProductEdit = () => {
    setIsProductEdit(true);
  };

  const setProductToEdit = (productId) => {
    const isProductInBasket = productsInBasket.find((product) => product.id === productId);

    if (isProductInBasket) {
      setProductToBeEdited(isProductInBasket);
    }
  };

  const updateProductInBasketCharacteristics = (editedProduct) => {
    const updatedProductsBasket = [...productsInBasket];
    const productIndex = updatedProductsBasket.findIndex((product) => {
      if (product.id === editedProduct.id) {
        return product;
      }
    });

    if (productIndex >= 0) {
      updatedProductsBasket[productIndex] = editedProduct;
      setProductsInBasket(updatedProductsBasket);
    }
    setIsProductEdit(false);
    setProductToBeEdited();
    history.push({ pathname: "/checkout", state: isLoggedIn ? 2 : 3 });
  };
  const plansHistory = createBrowserHistory();

  // Apply coupon coupon/discount
  const applyCoupon = (couponCode) => {
    //TODO check coupon in Backend
    const coupons = {
      CODE5: "5",
      CODE10: "10",
      CODE20: "20"
    };

    if (!isCouponApplied && coupons[couponCode]) {
      const applyDiscountOf = coupons[couponCode];
      const discount = (applyDiscountOf / 100) * totalCardSum;
      const newCardTotal = totalCardSum - discount;
      setIsCouponApplied(true);
      setTotalCartSum(newCardTotal);
    }
  };
  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        username,
        isMobile,
        handleLogin,
        isSubmenuDisplayed,
        toggleSubmenu,
        isSublevelDisplayed,
        toggleSubmenuSublevel,
        isMobileMenuContentDisplayed,
        isShopMenuDisplayed,
        isHelpMenuDisplayed,
        isMyVodafoneMenuDisplayed,
        isMobilesAndTabletsDisplayed,
        isBroadbandDisplayed,
        isSimOnlyDisplayed,
        isTVDisplayed,
        isWearablesDisplayed,
        isBundlesAndOffersDisplayed,
        isMyProductsDisplayed,
        toggleMyProducts,
        handleLogout,
        isOverlayDisplayed,
        toggleOverlay,
        productsInBasket,
        addProductToBasket,
        removeProductFromBasket,
        checkIfProductIsInBasket,
        calculateTotalBasketSum,
        setProductsInBasket,
        totalCardSum,
        updateProductQuantityFromButtons,
        getProductInBasketIndex,
        calculateProductQuantityInBasket,
        clearBasket,
        clientOrder,
        confirmOrder,
        resetClientOrder,
        isProductEdit,
        toggleProductEdit,
        setProductToEdit,
        productToBeEdited,
        updateProductInBasketCharacteristics,
        plansHistory,
        applyCoupon,
        isCouponApplied,
        isSearchDisplayed,
        toggleSearch
      }}>
      <Menu />
      <Main>
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <Suspense fallback={<Spinner />}>
                <Homepage />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/shop"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <EShop />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/shop/:product"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <EShop />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/shop/:product/:productname"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <ProductDetails />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/cart"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <Cart />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/checkout"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <Checkout />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/checkout-thankyou"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <ThankYou />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/search"
            render={() => (
              <Suspense fallback={<Loading delay={300} />}>
                <SearchResults />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/broadband"
            render={() => (
              <Suspense fallback={<Spinner />}>
                <BroadbandAvailability />
              </Suspense>
            )}
          />
          <Route
            exact
            path="/test"
            render={() => (
              <Suspense fallback={<Spinner />}>
                <TestPage />
              </Suspense>
            )}
          />
          <PrivateRoute exact path="/bills">
            <Suspense fallback={<Loading delay={300} />}>
              <Bills />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/billdetails/:yearmonth">
            <Suspense fallback={<Loading delay={300} />}>
              <BillDetails />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/myoffers">
            <Suspense fallback={<Loading delay={300} />}>
              <MyOffers />
            </Suspense>
          </PrivateRoute>
          <PrivateRoute exact path="/myoffers/:offername">
            <Suspense fallback={<Loading delay={300} />}>
              <MyOffersDetails />
            </Suspense>
          </PrivateRoute>
        </Switch>
      </Main>
      <Footer />
    </AppContext.Provider>
  );
}

export default App;
