
let configs;

export function init(config) {
    console.log('Setting configs: ', config);

    configs = config;
}

export function getConfigs() {
    return configs;
}
