import React from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../../ws10";
import { servicesToIcon } from "../../../../utils";
import PropTypes from "prop-types";

export const Content = ({ children, className }) => {
  return <div className={`card__content ${className ? className : ""}`}>{children}</div>;
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export const Text = ({ title, icon, subtitle, description }) => {
  let svgIcon;
  if (icon) {
    svgIcon = <Icon Source={Svg[icon]} size="small" />;
  }

  return (
    <>
      {title && <ContentTitle icon={icon} svgIcon={svgIcon} title={title} />}
      {subtitle && <ContentSubtitle subtitle={subtitle} />}
      {description && <ContentDescription description={description} />}
    </>
  );
};

Text.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  subtitle: PropTypes.string,
  description: PropTypes.string
};

export const Footer = ({ /* price, isDashboardCard, priceDescription, */ children }) => {
  return (
    <div className="card--bottom">
      {/* {price && <h4 className="heading heading--bold card--price">{price}</h4>}
      {isDashboardCard && priceDescription && <p className="card--price--description">{priceDescription}</p>} */}
      {children}
    </div>
  );
};

Footer.propTypes = {
  price: PropTypes.string,
  isDashboardCard: PropTypes.bool,
  priceDescription: PropTypes.string,
  children: PropTypes.node
};

export const ContentTitle = ({ icon, svgIcon, title }) => {
  return (
    <div className={`card--title ${icon ? "has--icon" : ""}`}>
      {icon && svgIcon}
      <h3 className="heading heading--bold">{title}</h3>
    </div>
  );
};

ContentTitle.propTypes = {
  icon: PropTypes.object,
  svgIcon: PropTypes.object,
  title: PropTypes.string
};

export const ContentSubtitle = ({ subtitle }) => <h4 className="heading heading--4 card--subtitle">{subtitle}</h4>;

ContentSubtitle.propTypes = {
  subtitle: PropTypes.string
};

export const ContentDescription = ({ description }) => <p className="card--description">{description}</p>;

ContentDescription.propTypes = {
  description: PropTypes.string
};

export const Price = ({ price }) => <h4 className="heading heading--bold card--price">{price}</h4>;

Price.propTypes = {
  price: PropTypes.string.isRequired
};

export const Link = ({ label, href }) => {
  return (
    <a className="card--button button--text" href={href}>
      {label}
      <Icon Source={Svg.ChevronRight} />
    </a>
  );
};

Link.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired
};

export const Button = ({ label, href, type }) => {
  return href ? (
    <a className={`card--button ${type ? type : "primary"}`} href={href}>
      {label}
    </a>
  ) : (
    <button className={`card--button ${type ? type : "primary"}`}>{label}</button>
  );
};

Button.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string,
  type: PropTypes.string.isRequired
};

export const ViewDetailsBtn = ({ label, updateSelectedPlanAndToggleOverlay, planName }) => (
  <button className="button button--details" onClick={() => updateSelectedPlanAndToggleOverlay(planName)}>
    {label}
    <Icon Source={Svg.InfoCircle} />
  </button>
);

ViewDetailsBtn.propTypes = {
  label: PropTypes.string.isRequired,
  updateSelectedPlanAndToggleOverlay: PropTypes.func,
  planName: PropTypes.string.isRequired
};

export const Services = ({ services }) => {
  return (
    <div className="card__services">
      <ul className="list--reset">
        {services.map((service, index) => (
          <li key={index}>
            <Icon Source={servicesToIcon[service.name]} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Services.propTypes = {
  services: PropTypes.array.isRequired
};

export const Options = ({ options }) => {
  return (
    <ul>
      {options.map((option, index) => (
        <li key={index}>
          <Icon Source={option.icon} />
          {option.text}
        </li>
      ))}
    </ul>
  );
};

Options.propTypes = {
  options: PropTypes.array.isRequired
};

export const Checkbox = ({ label, id }) => (
  <div className="plan__section--input">
    <input type="checkbox" name="" id={`compare-${id}`} />
    <label htmlFor={`compare-${id}`}>
      <span className="checkbox">
        <Icon Source={Svg.Tick} />
      </span>
      <span>{label}</span>
    </label>
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

// TODO this throws a React.minify error in dev, check task "Fix React.minify error" in Planner
/* function addComponentsToContent(...Components) {
  Components.map(Component => {
    Component.displayName = Component.name;
    Content[Component.name] = Component;
  });
}

addComponentsToContent(Text, Footer, Link, Button, ViewDetailsBtn, Services, Options, Checkbox, Price); */

export default Content;
