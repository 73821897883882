import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../../ws10";

function VodafoneIcon({ homepage, link }) {
  return (
    <li className="navigation__item">
      <Link to={link && link.url ? link.url : homepage} className="js-brand brand">
        <Icon Source={Svg.VodafoneLogo} className="brand__logo" />
      </Link>
    </li>
  );
}

VodafoneIcon.propTypes = {
  homepage: PropTypes.string.isRequired,
  link: PropTypes.object.isRequired
};

export default VodafoneIcon;
