import React from "react";
import PropTypes from "prop-types";

const ProgressBar = ({ percent, color = "primary" }) => {
  return (
    <div className={`progress progress--${color}`}>
      <div className="progress__bar" style={{ width: `${percent}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["primary", "secondary", "seance", "cerulean"])
};

export default ProgressBar;
