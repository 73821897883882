import React from "react";
import Button from "../Button";
import Form from "../Form";
import { Svg } from "../../../ws10";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

function LoginFormButton({ isFormFieldOk, handleClick, currentLoginStep, id }) {
  return (
    <>
      <Form.Row>
        <label className="form__checkbox">
          <input
            type="checkbox"
            id={`form-checkbox-${currentLoginStep}`}
            name="form-checkbox"
            className="form__checkbox__input"
          />
          <div htmlFor="form-checkbox-1" className="js-form-label form__checkbox__label">
            <div className="form__checkbox__icon">
              <Icon Source={Svg.Tick} />
            </div>
            <div className="form__checkbox__text">Remember me</div>
          </div>
          {/* Show if password step */}
          <div className="form__link--right">
            <a href="#">Forgot Password?</a>
          </div>
        </label>
      </Form.Row>
      <Form.Row>
        <Button id={id} disabled={!isFormFieldOk} onClick={handleClick} layout="fullWidth">
          Next
        </Button>
      </Form.Row>
      <div className="login__suggestion">
        Not registered with My Vodafone? <a href="#">Sign up</a>
      </div>
    </>
  );
}

LoginFormButton.propTypes = {
  isFormFieldOk: PropTypes.bool,
  handleClick: PropTypes.func,
  currentLoginStep: PropTypes.number
};

export default LoginFormButton;
