import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import NavigationMainLink from "./_NavigationMainLink";
import { Spring } from "@dc/react-components";
import { VodafoneIcon, SearchIcon, UserIcon as GenericUserIcon, UsernameIcon, ShoppingIcon } from "./icons";
import { getProductInBasketProperty, getCurrentTime } from "../../../utils";
import { setEventData } from "@dc/utag-tracking";
import AppContext from "../../../AppContext";
import PropTypes from "prop-types";

function getInitialsFromUsername(username) {
  if (!username) return "";
  const names = username.split(" ");
  return names.map((n) => n[0].toUpperCase()).join("");
}

const NavigationMenu = ({ navIcon, isSticky, homepage, links }) => {
  const {
    isMobile,
    username,
    handleLogout,
    toggleSubmenu,
    calculateProductQuantityInBasket,
    totalCardSum,
    isLoggedIn,
    productsInBasket,
    clientOrder,
    toggleSearch
  } = useContext(AppContext);

  const history = useHistory();
  const location = useLocation();

  // TODO refactor setCartData to allow reuse
  const setCartData = () => {
    return {
      cart_id: "122444",
      cart_total: totalCardSum, // TODO get most current data
      event_name: "cart_open",
      journey_type: "Online Purchase",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      //page_name: "Product details",
      //page_section: "Product showcase",
      page_time_on: getCurrentTime(),
      //page_title: location.pathname,
      //page_type: "Product Page",
      page_url: window.location.href,
      product_brand: getProductInBasketProperty(productsInBasket, "brand"),
      product_category: getProductInBasketProperty(productsInBasket, "category"),
      product_id: getProductInBasketProperty(productsInBasket, "id"),
      product_name: getProductInBasketProperty(productsInBasket, "name"),
      product_price: getProductInBasketProperty(productsInBasket, "price"),
      product_quantity: getProductInBasketProperty(productsInBasket, "quantity"),
      product_sku: "12345",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  const setToggleSearchData = (label) => {
    return {
      event_name: "ui_interaction",
      event_action: "OnClick",
      event_category: "Button",
      event_label: label,
      journey_type: "Menu",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      page_time_on: getCurrentTime(),
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  return (
    <>
      <div className="navigation-static"></div>
      <div
        className={`navigation navigation--primary navigation--primary-ws10 component__icon-background ${
          isSticky ? "navigation--fixed" : ""
        }`}
        data-js="_navigation">
        <Spring>
          <ul
            className="navigation__list navigation__spring navigation__hide navigation__hide--icons"
            role="navigation">
            {/* DesktopMenu */}
            <VodafoneIcon homepage={homepage} link={navIcon} />
            {!isMobile &&
              links &&
              links
                .slice(0, links.length - 1)
                .map((link, index) => (
                  <NavigationMainLink link={link} key={index} isMobileDevice={isMobile} linkIndex={index + 1} />
                ))}

            {!username ? (
              <GenericUserIcon
                title={"Sign in"}
                toggleSubmenu={() => toggleSubmenu(true, "My Vodafone")}
                iconNav={navIcon.iconClass}
                targetNav={navIcon.link && navIcon.link.target}
                opensDialog={false}
              />
            ) : (
              <UsernameIcon
                usernameInitials={getInitialsFromUsername(username)}
                handleLogout={handleLogout}
                isLoggedIn={isLoggedIn}
              />
            )}

            <SearchIcon
              toggleSearch={toggleSearch}
              onClick={() => setEventData(toggleSearch(), setToggleSearchData("Search icon"))}
            />

            <ShoppingIcon
              onClick={() =>
                setEventData(location.pathname === "/cart" ? null : () => history.push("/cart"), setCartData())
              }
              productIsInBasketQuantity={calculateProductQuantityInBasket}
              clientOrder={clientOrder}
            />
            {/* Mobile navigation menu and user icon links*/}
            {isMobile && (
              <div className="mega-menu--mobile__container">
                <NavigationMainLink
                  link={links.find((link) => link.title === "Menu")}
                  key={`mainLink${links.indexOf(links.find((link) => link.title === "Menu"))}`}
                  isMobileDevice={isMobile}
                  icon={true}
                />
              </div>
            )}
          </ul>
        </Spring>
      </div>
    </>
  );
};

NavigationMenu.propTypes = {
  navIcon: PropTypes.object,
  isSticky: PropTypes.bool,
  homepage: PropTypes.string,
  links: PropTypes.array
};

export default NavigationMenu;
