import { isEmpty } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../AppContext";
import Card from "../../components/web/Card/Card";
import CardBill from "../../components/web/Card/_CardBill";
import Grid from "../../components/web/Grid";
import { Section, Spring, List, Icon } from "@dc/react-components";
import Navigation from "./components/Navigation";
import { Svg } from "../../ws10";
import UsageConsumptionSection from "./components/UsageConsumptionSection";
import "./Dashboard.scss";
import "./DashboardEdit.scss";

import { getConfigs, balanceAndProductsServices, billServices } from "@dc/digital-channels-sdk";

//TODO general refractoring of trackView implementation (Tealium Context) and improve API calls code

/**
 * Function that return the bill card component
 * @param {object} bill - object with the properties of a bill return by the backend
 * @param {bool} visibility - sets the visibility of the component
 * @param {bool} edit - indicates if the page in edit mode or not so that component render in the proper way
 * @param {function} handleVisibilityToggle - function to be called when the user changes the visibility of the component
 */
function getBillCard(bill, visibility, edit, handleVisibilityToggle, loadingStatus, handleClick) {
  const { date, amount, currency } = bill;

  return (
    <Card
      title="Next Bill"
      icon={Svg.Report}
      hidden={!visibility}
      edit={edit}
      type="bill"
      handleVisibilityToggle={handleVisibilityToggle}
      onClickHandler={handleClick}>
      <CardBill amount={amount} currency={currency} date={date} isLoading={loadingStatus} />
    </Card>
  );
}
/**
 *  Calculates the number of visible elements in the grid
 * @param {object} buckets - product buckets return by the backend
 * @param {object} settings - object with the user settings
 * @param {bool} editMode - indicates if the page in edit mode or not so that component render in the proper way
 */
function getGridNumberOfElements(buckets, settings, editMode) {
  if (editMode) {
    return buckets.length;
  }
  let numVisibleElements = 0;
  buckets.forEach((bucket) => {
    const visibility = settings[bucket.usageType];
    if (visibility) {
      numVisibleElements += 1;
    }
  });
  return numVisibleElements;
}

const currentUsageStartDate = "2020-11-23";
const currentUsageEndDate = "2020-12-23";

const removeDecimalsFromNumber = (numberToConvert) => numberToConvert.split(".")[0];
const convertStringToLowerCase = (stringToConvert) => stringToConvert.toLowerCase();

const convertToGigabite = (numberToConvert) => {
  const getGB = numberToConvert / 1000;
  return getGB.toFixed();
};

function getDashboardBuckets(dataConsumption, voiceConsumption) {
  return [
    {
      name: `My ${dataConsumption.usageType.charAt(0).toUpperCase() + dataConsumption.usageType.slice(1)}`,
      isShared: dataConsumption.isShared,
      usageType: dataConsumption.usageType,
      validFor: {
        startDateTime: currentUsageStartDate,
        endDateTime: currentUsageEndDate
      },
      remainingValueName: dataConsumption.bucketBalance[0]
        ? `${convertToGigabite(dataConsumption.bucketBalance[0].remainingValue.amount)}`
        : dataConsumption.bucketCounter[0].value.amount,
      remainingValue: {
        amount: dataConsumption.bucketBalance[0]
          ? convertToGigabite(dataConsumption.bucketBalance[0].remainingValue.amount)
          : dataConsumption.bucketCounter[0].value.amount,
        units: "GB" //FIXME dataConsumption.bucketBalance[0].remainingValue.units
      },
      valueName: `${convertToGigabite(dataConsumption.bucketCounter[0].value.amount)}GB`,
      counterType: "total",
      value: {
        amount: `${convertToGigabite(dataConsumption.bucketCounter[0].value.amount)}`,
        units: "GB" //FIXME dataConsumption.bucketBalance[0].remainingValue.units
      }
    },
    {
      name: "Local calls",
      isShared: voiceConsumption.isShared,
      usageType: voiceConsumption.usageType,
      validFor: {
        startDateTime: currentUsageStartDate,
        endDateTime: currentUsageEndDate
      },
      remainingValueName: voiceConsumption.bucketBalance[0]
        ? `${removeDecimalsFromNumber(voiceConsumption.bucketBalance[0].remainingValueName)}`
        : voiceConsumption.bucketCounter[0].value.amount,
      remainingValue: {
        amount: voiceConsumption.bucketCounter[0].value.amount,
        units: voiceConsumption.bucketBalance[0]
          ? convertStringToLowerCase(voiceConsumption.bucketBalance[0].remainingValue.units)
          : voiceConsumption.bucketCounter[0].value.units
      },
      valueName: voiceConsumption.bucketBalance[0]
        ? `${voiceConsumption.bucketCounter[0].value.units} ${voiceConsumption.bucketBalance[0].remainingValue.units}s`.toLowerCase()
        : `${voiceConsumption.bucketCounter[0].value.amount} ${voiceConsumption.bucketCounter[0].value.units}s`.toLowerCase(),
      counterType: "total",
      value: {
        amount: voiceConsumption.bucketCounter[0].value.units,
        units: voiceConsumption.bucketBalance[0]
          ? voiceConsumption.bucketBalance[0].remainingValue.units
          : voiceConsumption.bucketCounter[0].value.units
      }
    }
  ];
}

function modifyBuckets(buckets) {
  const _buckets = buckets.map((bucket) => getInfoFromBucket(bucket));
  return _buckets;
}

function getInfoFromBucket(bucket) {
  const bucketResume = {
    usageType: bucket.usageType,
    name: bucket.name,
    endDateTime: bucket.validFor.endDateTime,
    remainingValueName: bucket.remainingValueName,
    remainingValueAmount: bucket.remainingValue.amount,
    remainingValueUnits: bucket.remainingValue.units,
    counterType: bucket.counterType,
    totalValueName: bucket.valueName,
    totalValueAmount: bucket.value.amount,
    totalValueUnits: bucket.value.units
  };

  return bucketResume;
}

function Dashboard() {
  //FIXME clear this legacy
  const editMode = false;
  const history = useHistory();

  const [dashboardData, setDashboardData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // TODO restore tracking
  //const [canSendNetworkData, setCanSendNetworkData] = useState(false);

  const { isLoggedIn, isMobile, isSubmenuDisplayed, toggleSubmenu, toggleSubmenuSublevel } = useContext(AppContext);

  useEffect(() => {
    if (isLoggedIn && isSubmenuDisplayed) {
      const userData = JSON.parse(getConfigs().storage.getItem("accessToken"));
      const id = userData.productId;
      // TODO get this data from API?
      const assetType = "Fixed";
      const paymentType = "Postpay";

      if (isEmpty()) {
        loadDashboardPage(id, assetType, paymentType);
      }
    }
  }, [isLoggedIn, isSubmenuDisplayed]);

  // TODO restore tracking
  /*  useEffect(() => {
    if (canSendNetworkData && isSubmenuDisplayed) {
      setStartingPageData(utagData());
      trackView();
    }
  }, [canSendNetworkData, isSubmenuDisplayed]); */

  /**
   * In order to access this.props, the method needs to be binded to the this in the constructor of this.
   * @param {String} cardType
   */
  const handleVisibilityToggle = (cardType) => {
    const updateSettings = { ...dashboardData.currentSettings };
    // toggle of the visibility of the card in the currentSettings
    updateSettings[cardType] = !dashboardData.currentSettings[cardType];
  };

  const handleMyOffersClick = () => {
    //TODO define Routes file
    toggleSubmenu(false);
    history.push("/myoffers");
  };

  const onBillClick = () => {
    toggleSubmenu(false);
    history.push("/bills");
  };

  const dashBoardLinks = [
    {
      title: "My products",
      iconSrc: Svg.ConnectedDevices,
      onClick: () => toggleSubmenuSublevel(true, "My Products")
    },
    {
      title: "Account",
      iconSrc: Svg.Business,
      onClick: () => {}
    },
    {
      title: "Billing",
      iconSrc: Svg.Report,
      onClick: onBillClick
    },
    {
      title: "Top up",
      iconSrc: Svg.TopUp,
      onClick: () => {}
    },
    {
      title: "Broadband",
      iconSrc: Svg.Broadband,
      onClick: () => toggleSubmenuSublevel(true, "Broadband")
    }
  ];

  async function loadDashboardPage(subscriptionId, subscriptionType, billingType) {
    setIsLoading(true);
    const getEligibleProducts = (dataConsumption) => {
      return dataConsumption.eligibleProducts.map((product) => {
        return {
          id: product.id[0].value
        };
      });
    };

    const subscriptionParam = subscriptionType.charAt(0).toUpperCase() + subscriptionType.slice(1);

    let billBalance, balanceAndProducts;

    let userConsumptionData = {
      settings: {
        data: true,
        messages: true,
        voice: true,
        bill: true,
        offers: true,
        discover: true,
        topUp: true
      },
      personalization: { darkMode: false }
    };

    const userData = JSON.parse(getConfigs().storage.getItem("accessToken"));
    const userType = "Payer"; // FIXME should come from userData/api

    //TODO confirm how to handle fields that must/might be manipulated with JS (usateType --> name, etc)
    try {
      billBalance = await billServices.balance(subscriptionId, userData, userType);
      balanceAndProducts = await balanceAndProductsServices(subscriptionId, subscriptionParam, billingType);

      if (billBalance) {
        const billBalanceData = billBalance.bill;
        userConsumptionData = {
          ...userConsumptionData,
          billBalance: {
            bill: {
              amount: billBalanceData && billBalanceData.amountDue.value,
              currency: billBalanceData && billBalanceData.amountDue.unit,
              date: billBalanceData && billBalanceData.paymentDueDate
            }
          }
        };
      }

      if (balanceAndProducts) {
        const dataConsumption = balanceAndProducts.bucket.find((bucket) => bucket.usageType === "data");
        const voiceConsumption = balanceAndProducts.bucket.find((bucket) => bucket.usageType === "voice");

        userConsumptionData = {
          ...userConsumptionData,
          balanceAndProducts: {
            bucket: getDashboardBuckets(dataConsumption, voiceConsumption),
            eligibleProducts: getEligibleProducts(balanceAndProducts)
          }
        };
      }

      const userDashboardData = {
        ...userConsumptionData,
        balanceAndProducts: {
          ...userConsumptionData.balanceAndProducts,
          bucket: modifyBuckets(userConsumptionData.balanceAndProducts.bucket)
        },
        currentSettings: userConsumptionData.settings,
        abTests: userConsumptionData.abTests
      };

      setDashboardData(userDashboardData);
      setIsLoading(false);
      // TODO restore tracking
      //setCanSendNetworkData(true);
    } catch (error) {
      setIsLoading(false);
      setDashboardData();
      console.debug(error);
    }
  }

  return (
    <Section className="component dashboard--submenu" style={{ left: isLoggedIn && 0 }}>
      <div className="dashboard">
        <Spring size="large">
          {isMobile && (
            <>
              <a className="back-button" onClick={() => toggleSubmenu(true, "Menu")}>
                <Icon Source={Svg.ArrowLeft} />
              </a>
            </>
          )}
          {dashboardData && dashboardData.balanceAndProducts ? (
            <Grid className="dashboard__content" hasHorizontalGutter>
              <UsageConsumptionSection
                gridNumberOfElements={
                  dashboardData &&
                  getGridNumberOfElements(
                    dashboardData.balanceAndProducts.bucket,
                    dashboardData.currentSettings,
                    editMode
                  )
                }
                buckets={dashboardData && dashboardData.balanceAndProducts.bucket}
                settings={dashboardData && dashboardData.currentSettings}
                editMode={editMode}
                handleVisibilityToggle={handleVisibilityToggle}
              />
            </Grid>
          ) : (
            <UsageConsumptionSection buckets={[]} settings={{}} />
          )}
        </Spring>
        <Spring size="large">
          <Grid className="dashboard__content dashboard__content--flex" hasHorizontalGutter>
            <Grid.Item className="dashboard__item--1/2" width="1/2" mdWidth="1/2" smWidth="1/2">
              {!isEmpty(dashboardData)
                ? getBillCard(
                    dashboardData.billBalance.bill,
                    dashboardData.currentSettings.bill,
                    editMode,
                    handleVisibilityToggle,
                    isLoading,
                    () => onBillClick()
                  )
                : getBillCard({}, {}, null, null, isLoading, () => onBillClick())}
            </Grid.Item>

            {
              <Grid.Item className="dashboard__item--1/2" width="1/2" mdWidth="1/2" smWidth="1/2">
                <Grid className="dashboard__content--double dashboard__content--flex">
                  <Grid.Item width="1/1" mdWidth="1/1" smWidth="1/1">
                    <Card
                      title="My offers"
                      bgcolor="img"
                      icon={Svg.AllRewardsMid}
                      hidden={
                        dashboardData && dashboardData.currentSettings ? !dashboardData.currentSettings.offers : false
                      }
                      edit={editMode}
                      type="offers"
                      handleVisibilityToggle={handleVisibilityToggle}
                      onClickHandler={() => handleMyOffersClick()}>
                      <div className="card__link">View all</div>
                    </Card>
                  </Grid.Item>
                  <Grid.Item width="1/1" mdWidth="1/1" smWidth="1/1">
                    <Card
                      title="Top-up"
                      icon={Svg.TopUp}
                      hidden={
                        dashboardData && dashboardData.currentSettings ? !dashboardData.currentSettings.topUp : false
                      }
                      edit={editMode}
                      type="topUp"
                      handleVisibilityToggle={handleVisibilityToggle}>
                      <div className="card__info top--up">
                        12.50€
                        <span>Lily&apos;s phone</span>
                      </div>
                    </Card>
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            }
            <Grid.Item width="1/1" mdWidth="1/1" smWidth="1/1" className="dashboard__menu">
              <List isReset>
                {dashBoardLinks.map((link, index) => (
                  <Navigation key={index} {...link} />
                ))}
              </List>
            </Grid.Item>
          </Grid>
        </Spring>
      </div>
    </Section>
  );
}

export default Dashboard;
