import { Heading, Spring } from "@dc/react-components";
import React, { useState } from "react";
import Button from "../../../src/components/web/Button/";
import Form from "../../../src/components/web/Form/";
import Spinner from "../../../src/components/web/Spinner/";
import UltraHub from "../../assets/images/ultra-hub.png";
import Breadcrumbs from "../../components/web/Breadcrumbs/Breadcrumbs";
import Banner from "../../components/web/ws10/Banner/Banner";
import "./BroadbandAvailability.scss";
import CardGroup from "../../../src/components/web/ws10/CardGroup";
import PlanCard from "../../../src/components/web/ws10/Card/templates/product_and_plan/PlanCard";
import { broadbandData } from "../eshop/EshopBroadbandData";
export default function BroadbandAvailability() {
  const [postcode, setPostcode] = useState("");
  const [availableBroadbandPlans, setAvailableBroadbandPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availabilityChecked, setAvailabilityChecked] = useState(false);
  const breadcrumbPath = [{ label: "Home", link: "/" }, { label: "Broadband" }];
  const handleInputPostcode = (e) => {
    setPostcode(e.target.value);
  };

  function onFocusInput(e) {
    const inputElement = e.target;
    const label = inputElement.parentElement.parentElement;
    const floatingLabel = inputElement.parentElement.previousSibling;
    if (inputElement.value === "") {
      label.classList.add("on-focus");
    }
    floatingLabel.classList.add("focus");
  }

  function handleInputFocusOut(e) {
    const input = e.target;
    const label = input.parentElement.parentElement;
    const floatingLabel = input.parentElement.previousSibling;
    if (input.value === "") {
      label.classList.remove("on-focus");
    }
    floatingLabel.classList.remove("focus");
  }
  const handleSubmit = () => {
    setIsLoading(true);
    const plans = broadbandData.filter((bb) => bb.availablePostalCodes.includes(postcode));
    console.log(plans);
    setAvailableBroadbandPlans(plans);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    if (!availabilityChecked) setAvailabilityChecked(true);
  };
  const bannerData = {
    headingText: "Get a discount on our fibre broadband",
    buttonText: "Learn more",
    imageUrl: UltraHub,
    imageAlt: "ultrahub"
  };
  return (
    <>
      <section className="section flush--bottom">
        <Breadcrumbs path={breadcrumbPath} />
      </section>
      <Banner
        headingText={bannerData.headingText}
        buttonText={bannerData.buttonText}
        imageAlt="alt"
        imageUrl={UltraHub}
      />
      <Spring>
        <Heading level="3" component="h3" className="broadband--title">
          Enter your postcode to check the available plans for you
        </Heading>
        <div className="broadband">
          <div className="broadband--form">
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Input
                  label="Enter Postcode"
                  name="postcode"
                  onChange={(event) => handleInputPostcode(event)}
                  onFocus={(event) => onFocusInput(event)}
                  onBlur={(event) => handleInputFocusOut(event)}
                  value={postcode}
                  isRequired
                  className="form"
                />
              </Form.Row>
            </Form>
            <Button onClick={handleSubmit} disabled={isLoading} className="btn">
              Check Availability
            </Button>
          </div>
        </div>
        {isLoading ? (
          <div style={{ marginTop: "100px" }}>
            <Spinner />
          </div>
        ) : (
          <>
            {availableBroadbandPlans.length > 0 && (
              <div className="plans">
                <CardGroup orientation="simpleGrid" numberOfItems={3} aditionalnumberOfItems={1}>
                  {availableBroadbandPlans.map((broadband, index) => {
                    return (
                      <PlanCard
                        key={index}
                        id={broadband.id}
                        planIcon={broadband.icon}
                        planName={broadband.name}
                        productCategory={broadband.category[0].name}
                        price={broadband.price}
                        recurringChargePeriodType={broadband.recurringChargePeriodType}
                        description={broadband.description}
                        options={broadband.options}
                        isHighlighted={broadband.highlighted}
                        highlightLabel={broadband.highlightedText}
                        updateSelectedPlanAndToggleOverlay={() => {}}
                        hasCompare={false}
                        buttonText={broadband.buttonText}
                      />
                    );
                  })}
                </CardGroup>
              </div>
            )}
            {availableBroadbandPlans.length === 0 && availabilityChecked && (
              <Heading level="4" component="h4" className="broadband--title">
                There are no available broadband plans
              </Heading>
            )}
          </>
        )}
      </Spring>
    </>
  );
}
