import React, { useContext } from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import AppContext from "../../../AppContext";
import { broadbandData } from "../../Content/utilsCardsData";
import PlanCard from "../ws10/Card/templates/product_and_plan/PlanCard";

const Broadband = () => {
  const { toggleSubmenuSublevel, removeProductFromBasket } = useContext(AppContext);

  return (
    <>
      <div className="broadband spring">
        <div className="submenu__list">
          <a className="back-button" onClick={toggleSubmenuSublevel}>
            {" "}
            <Icon Source={Svg.ArrowLeft} />
          </a>
          <br />
          <div className="flex">
            <h2 className="broadband-heading">Your Offer</h2>
            <a className="submenu__link--red __broadband">
              Compare plans&nbsp;
              <Icon Source={Svg.Bundles} />
            </a>
          </div>
        </div>
      </div>

      <div className="broadband-product">
        {broadbandData.map((broadband, index) => (
          <div key={index} style={{ marginBottom: "50px" }}>
            <PlanCard
              id={broadband.id}
              planIcon={broadband.icon}
              planName={broadband.name}
              productCategory={broadband.category}
              price={broadband.price}
              recurringChargePeriodType={broadband.recurringChargePeriodType}
              description={broadband.description}
              options={broadband.options}
              isHighlighted={broadband.highlighted}
              highlightLabel={broadband.highlightedText}
              buttonText={broadband.buttonText}
              removeProduct={removeProductFromBasket}
              includeModal={true}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default Broadband;
