import React from "react";
import PropTypes from "prop-types";
import { Heading } from "@dc/react-components";
import Spinner from "../Spinner";

import { getMonthFullnameFromDate, getOrdinalFromNumber, getDayOfMonthFromDate, currencySymbols } from "../../../utils";

function CardBill({ amount, currency, date, isLoading }) {
  const noBillAmount = "N/A";
  const billCurrency = currency || "N/A";
  const billDate = date || "N/A";

  let renderedDate;
  if (date) {
    renderedDate = `${getMonthFullnameFromDate(date)} ${getOrdinalFromNumber(getDayOfMonthFromDate(billDate))}`;
  }

  let noDataClass;
  if (isLoading) {
    noDataClass = "card--amount-small";
  } else {
    if (!amount) {
      noDataClass = "card--amount-small";
    } else {
      noDataClass = "";
    }
  }

  return (
    <div className="card__info card__info--bill">
      <Heading level={5} component="h5">
        Your current spend is:
      </Heading>
      <div className={`card--amount ${noDataClass}`}>
        {isLoading ? <Spinner /> : amount ? Number.parseFloat(amount).toFixed(2) : noBillAmount}
        <span>{currencySymbols[billCurrency]}</span>
        {renderedDate && <p>To be paid on&nbsp;{renderedDate}</p>}
      </div>
    </div>
  );
}

CardBill.propTypes = {
  amount: PropTypes.number,
  currency: PropTypes.string,
  date: PropTypes.string,
  isLoading: PropTypes.bool
};

export default CardBill;
