import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Loading = ({ delay = 0 }) => {
  const [ready, setReady] = useState(delay === 0);

  useEffect(() => {
    let timeout = null;
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  if (!ready) return null;

  return (
    <div>
      <div>Loading...</div>
    </div>
  );
};

Loading.propTypes = {
  delay: PropTypes.number
};

export default Loading;
