import React from "react";
import { Heading, Icon, Spring } from "@dc/react-components";
import { Svg } from "../../../../ws10";
import PropTypes from "prop-types";

import "./Banner.scss";

const Banner = ({ headingText, buttonText, imageUrl, imageAlt }) => {
  return (
    <section className="section banner banner--small banner--red">
      <Spring className="banner__container">
        <div className="banner__content">
          <Heading level={4} component="h2" fontWeight="bold">
            {headingText}
          </Heading>
          <a href="#" className="button button--secondary has--chevron">
            {buttonText}
            <Icon Source={Svg.ChevronRight} />
          </a>
        </div>
        <div className="banner__image">
          <img src={imageUrl} alt={imageAlt} />
        </div>
      </Spring>
    </section>
  );
};

Banner.propTypes = {
  headingText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired
};

export default Banner;
