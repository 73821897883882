import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index";
import { PROXY_HEADERS, serializeUrlParams } from "../utils/Http.utils";

/*
  Fetch balance and products from DXL
*/
export async function getBalanceAndProductsData(subscriptionId, subscriptionParam, billingType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  const apiUrl = configs.balanceAndProducts;
  const subscriptionTypeParam = `subscription.type`;
  const subscriptionIdParam = `subscription.id`;

  const baseParams = `parts.subscription.characteristic.characteristicsValues[?(@.characteristicName==’BillingType’)].value`;
  const params = {};
  params[subscriptionTypeParam] = subscriptionParam;
  params[subscriptionIdParam] = subscriptionId;
  params[baseParams] = billingType;

  const serviceName = "getBalanceAndProducts";
  const requestData = {
    endpoint: apiUrl,
    method: "GET",
    request: {
      subscriptionId,
      subscriptionParam,
      billingType
    }
  };

  configs.setApiRequest(serviceName, requestData);

  try {
    const response = await axios.get(apiUrl, {
      params,
      paramsSerializer: () => serializeUrlParams(params),
      headers: PROXY_HEADERS.headers,
      withCredentials: PROXY_HEADERS.withCredentials
    });

    if (response) {
      configs.setApiResponse(serviceName, response.data);
      return response.data;
    }
  } catch (error) {
    const data = _get(error, "response.data");
    if (data) {
      throw new Error(JSON.stringify(data));
    }
    throw error;
  }
}
