import React from "react";
import PropTypes from "prop-types";
import TopLink from "./_TopLink";
import { Spring } from "@dc/react-components";

function TopNavigationBar({ consumerLink, businessLink, extraSiteLinks }) {
  return (
    <div className="navigation navigation_ws10">
      <Spring className="spring">
        <ul className="navigation__list navigation__global navigation__hide navigation__hide--brand no-gutter--all">
          <li className="navigation__spring">
            <ul className="navigation navigation__list">
              <TopLink link={consumerLink} active={true} />
              <TopLink link={businessLink} />
            </ul>
            <ul className="navigation navigation__list">
              {extraSiteLinks.map((link, index) => (
                <TopLink key={index} link={link} icon={link.icon} />
              ))}
            </ul>
          </li>
        </ul>
      </Spring>
    </div>
  );
}

TopNavigationBar.propTypes = {
  consumerLink: PropTypes.object.isRequired,
  businessLink: PropTypes.object.isRequired,
  extraSiteLinks: PropTypes.array
};

export default TopNavigationBar;
