import React, { useRef } from "react";
import PropTypes from "prop-types";
function ColumnsMenu({ children }) {
  const ref = useRef(null);

  return (
    <nav className="header header__sticky" data-js="_language" ref={ref}>
      {children}
    </nav>
  );
}

ColumnsMenu.propTypes = {
  children: PropTypes.node,
};

export default ColumnsMenu;
