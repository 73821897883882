import React from "react";
import PropTypes from "prop-types";

const clickHOC = (OriginalComponent) => {
  class WithClickAnalytics extends React.Component {
    constructor(props) {
      super(props);

      this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
      const { analyticsComponent, analyticsComponentType } = this.props;
      const { subtype, target } = analyticsComponent.content;

      fireClick(subtype, target, resolveJson(analyticsComponent, analyticsComponentType));
    }

    render() {
      return <OriginalComponent handleClick={this.handleClick} {...this.props} />;
    }
  }
  WithClickAnalytics.propTypes = {
    analyticsComponent: PropTypes.object,
    analyticsComponentType: PropTypes.string,
  };
  return WithClickAnalytics;
};

export default clickHOC;

const fireClick = (linkType, linkTarget, json) => {
  console.log("Clicked", linkType, linkTarget, json);
};

fireClick.propTypes = {
  linkType: PropTypes.string,
  linkTarget: PropTypes.string,
  json: PropTypes.object,
};

function getComponentConstants(componentType) {
  if (componentType === "Menu") {
    return {
      tealiumEvent: "CTA navigation menu click",
      component: {
        type: "Navigation menu",
      },
      content: {
        position: "Not applicable",
      },
    };
  }
}

function resolveJson(element, componentType) {
  const constants = getComponentConstants(componentType);

  return {
    tealium_event: constants.tealiumEvent,
    CTA_component: {
      name: element.component.name,
      type: constants.component.type,
      CTA_content: {
        name: element.content.title,
        type: element.content.subtype,
        url: element.content.url,
        position: constants.content.position,
      },
    },
  };
}
