import { Svg } from "./ws10";

export function getUserAuthData() {
  const authDataString = sessionStorage.getItem("accessToken");
  if (authDataString) {
    return JSON.parse(authDataString);
  }
  return null;
}

export function getYearFromDate(date) {
  return new Date(date).getFullYear();
}

export function getMonthFullnameFromDate(date) {
  return new Date(date).toLocaleString("EN", { month: "long" });
}

export function getMonthShortnameFromDate(date) {
  return new Date(date).toLocaleString("EN", { month: "short" });
}

export function getDayOfMonthFromDate(date) {
  return new Date(date).getDate();
}

export function getNumericMonthFromDate(date) {
  const newDate = new Date(date);
  const month = new Intl.DateTimeFormat("en-UK", { month: "numeric" }).format(newDate);
  if (month <= 9) {
    return 0 + month;
  } else {
    return month;
  }
}

export function getCurrentTime() {
  const date = new Date();

  const options = {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false
  };

  return new Intl.DateTimeFormat("default", options).format(date);
}

export function getOrdinalFromNumber(number) {
  const setOrdinal = new Intl.PluralRules("en-UK", {
    type: "ordinal"
  });

  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th"
  };
  return `${number}${suffixes[setOrdinal.select(number)]}`;
}

export const currencySymbols = {
  // US Dollar
  USD: "$",
  dollars: "$",
  // Euro
  EUR: "€",
  euros: "€",
  // British Pound Sterling
  GBP: "£",
  pounds: "£"
};

//TODO remove when API returns brand field
export const getBrandFromString = (string) => {
  if (string.match("Samsung")) {
    return "Samsung";
  }
  if (string.match("Apple")) {
    return "Apple";
  }
  if (string.match("Motorola")) {
    return "Motorola";
  }
  if (string.match("Sony")) {
    return "Sony";
  }
  if (string.match("FluffyCloud")) {
    return "Fluffy Cloud";
  }
  if (string.match("Otterbox")) {
    return "Otterbox";
  }
  return null;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const uncapitalizeWord = (s) => {
  if (typeof s !== "string") return "";
  return s.toLowerCase();
};

export const getFirstWordFromString = (s) => {
  return s.replace(/ .*/, "");
};

export const removeFirstWordFromString = (s) => {
  return s.split(" ").slice(1).join(" ");
};

// Shop and Products related functions
export function getExtraProductDataFromCMS(database, urlCategoryName, currentProductId) {
  const productCategory = database[urlCategoryName];
  const extraProductData = productCategory.find((product) => product.id === currentProductId);
  if (extraProductData) {
    return extraProductData;
  }
}

export function getSpecCharacteristic(productSpecs, spec) {
  return productSpecs.find((item) => item.name === spec);
}

export function getProductColorsFromCMS(database, productCategory, productId, productColors) {
  const category = database[productCategory];
  const currentProductColors = category.find((product) => product.id === productId).colorOptions;
  let cmsProductColor = [];
  if (productColors) {
    productColors.productSpecCharacteristicValue.forEach((colorSpec) => {
      currentProductColors.find((color) => {
        if (color.hex === colorSpec.value) {
          cmsProductColor.push(color);
        }
      });
    });
    return cmsProductColor;
  }
}

export function getColorName(colors, currentColor) {
  let colorName;
  colors.forEach((color) => {
    if (color.hex === currentColor) {
      colorName = color.name;
    } else {
      return currentColor;
    }
  });
  return colorName;
}

export function getProductStorageFromCMS(database, productCategory, productId, productStorage) {
  let cmsProductStorage = [];
  if (productStorage) {
    const category = database[productCategory];
    const currentProductStorage = category.find((product) => product.id === productId).storageOptions;

    productStorage.productSpecCharacteristicValue.forEach((storageSpec) => {
      currentProductStorage.find((storage) => {
        if (storage.value === storageSpec.value) {
          cmsProductStorage.push(storage);
        }
      });
    });
  }
  return cmsProductStorage;
}

// Get bundle offering info from a given product if bundle service has an icon
export function getBundledOfferingInfo(productBundle) {
  if (productBundle) {
    const offeringWithIcon = productBundle.filter((bundle) => bundle.attachment);

    if (offeringWithIcon.length > 0) {
      return offeringWithIcon;
    }
  }
}

// Get a product property (e.g. brand, name) from basket
export function getProductInBasketProperty(basket, property) {
  if (basket) {
    const productsProperty = basket.map((product) => product[property]);
    if (productsProperty) {
      return productsProperty;
    }
  }
  return null;
}

// Get product from basket
export function getProductFromBasketById(basket, productId) {
  return basket.find((product) => product.id === productId);
}
export const reducePriceBy = 70;
export const incresePriceBy = 90;

// Get partial sum of products in the Shopping cart
export function partialCartSum(productsInCart, categories) {
  let productsOfGivenCategory = [];

  for (let i = 0; i < productsInCart.length; i++) {
    for (let j = 0; j < categories.length; j++) {
      if (productsInCart[i].category === categories[j]) {
        productsOfGivenCategory.push(productsInCart[i]);
      }
    }
  }

  return (
    productsOfGivenCategory &&
    productsOfGivenCategory
      .map((product) => product.price * product.quantity)
      .reduce((a, b) => {
        return a + b;
      }, 0)
  );
}

// Match product bundle service with icon
export const servicesToIcon = {
  Netflix: Svg.Netflix,
  Spotify: Svg.Spotify,
  Facebook: Svg.FacebookColor,
  YouTube: Svg.Youtube
};

// Turn an array of strings into a string
export function stringifyArray(data) {
  return data.map((product) => product.label).toString();
}

// Return unique value from an Array
export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

// Match shop products with icons
export const productIcons = {
  phones: Svg.Mobile,
  accessories: Svg.Camera,
  offers: Svg.SimSingle,
  plans: Svg.AccelerationLowMid,
  broadband: Svg.Broadband
};
