import { Svg } from "../../../../ws10";

const searchData = {
  closeIcon: Svg.Close,
  title: "Search",
  inputType: "text",
  inputPlaceholder: "Type anything",
  searchIcon: Svg.Search
};

export default searchData;
