import React from "react";
import clickHOC from "./_clickHoc";
import MainLink from "./_MainLink";
import PropTypes from "prop-types";

const HOCMainLink = clickHOC(MainLink);

function NavigationMainLink({ link, isMobileDevice, icon }) {
  return (
    <>
      <li className={`navigation__item`}>
        <HOCMainLink
          link={link}
          isMobileDevice={isMobileDevice}
          analyticsComponentType="Menu"
          analyticsComponent={{
            component: {
              name: link.title,
            },
            content: link,
          }}
          icon={icon}
        />
      </li>
    </>
  );
}

NavigationMainLink.propTypes = {
  link: PropTypes.object.isRequired,
  isMobileDevice: PropTypes.bool.isRequired,
  icon: PropTypes.bool,
};

export default NavigationMainLink;
