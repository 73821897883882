import classnames from "classnames";
import * as React from "react";
import Clickable from "../Clickable";
import PropTypes from "prop-types";

// type Props = {
//   children: React.Node,
//   action?: ClickableAction,
//   onClick?: () => void,
//   isBlock?: boolean,
//   isBody?: boolean,
//   hasDarkBackground?: boolean,
//   stopPropagation?: $PropertyType<ClickableProps, "stopPropagation">,
//   className?: string
// };

const Link = ({
  children,
  action,
  onClick,
  isBlock,
  isBody,
  hasDarkBackground,
  stopPropagation = false,
  className,
  ...props
}) => {
  return (
    <Clickable
      className={classnames("link", className, {
        "link--body": isBody,
        "link--block": isBlock,
        "link--dark": hasDarkBackground, // NOTE: Not importing the .link--body--dark modifier as it's redundant.
      })}
      {...props}
      {...action}
      {...{
        children,
        stopPropagation,
        onClick,
      }}
    />
  );
};

Link.propTypes = {
  children: PropTypes.node,
  action: PropTypes.object,
  onClick: PropTypes.func,
  isBlock: PropTypes.bool,
  isBody: PropTypes.bool,
  hasDarkBackground: PropTypes.bool,
  stopPropagation: PropTypes.func,
  className: PropTypes.string,
};

export default Link;
