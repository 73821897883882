import React from "react";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

function NavigationIcon({
  target,
  title,
  url,
  icon,
  svgIcon,
  svgIconSize,
  dataStamp,
  onClick,
  opensDialog,
  extraClasses,
  id
}) {
  return (
    <a
      href={url}
      onClick={onClick}
      id={id}
      target={target}
      className={`navigation__link navigation__link--icon${extraClasses ? extraClasses : ""}`}
      data-stamp={dataStamp}
      data-js={opensDialog ? "_dialog" : "_dialog-deactivate"}>
      <span className="visually-hidden">{title}</span>
      {icon ? (
        <svg focusable="false" aria-hidden="true" className="icon icon--small navigation__icon">
          <use xlinkHref={icon} />
        </svg>
      ) : (
        <Icon Source={svgIcon} size={svgIconSize} className="icon navigation__icon" />
      )}
    </a>
  );
}

NavigationIcon.propTypes = {
  target: PropTypes.object,
  title: PropTypes.string,
  url: PropTypes.string,
  icon: PropTypes.object,
  svgIcon: PropTypes.object,
  svgIconSize: PropTypes.string,
  dataStamp: PropTypes.string,
  onClick: PropTypes.func,
  opensDialog: PropTypes.func,
  extraClasses: PropTypes.string,
  id: PropTypes.string
};

export default NavigationIcon;
