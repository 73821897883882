import { resetUtagNetworkData } from "./utagNetworkData";

function sendData(utagData) {
  let data = utagData;
  window.utag_data = { ...data };
  window.utag.view(data);
  resetUtagNetworkData();
}

export default function viewTrackEvent(networkData) {
  let utagData;

  if (Array.isArray(networkData)) {
    networkData.forEach((utagObject) => {
      for (const value of Object.values(utagObject)) {
        utagData = value;
        sendData(utagData);
      }
    });
  } else {
    utagData = networkData;
    sendData(utagData);
  }
}
