import React from "react";
import { List } from "@dc/react-components";
import FooterBottomItem from "./FooterBottomItem";

const data = [
  {
    url: "#",
    linkText: "Site map"
  },
  {
    url: "#",
    linkText: "Terms & conditions"
  },
  {
    url: "#",
    linkText: "Privacy policy"
  },
  {
    url: "#",
    linkText: "Cookie policy"
  }
];

const FooterBottom = () => {
  return (
    <div className="footer__bottom">
      <List className="list--reset">
        {data.map((el, index) => (
          <FooterBottomItem key={index} {...el} index={index} />
        ))}
      </List>
    </div>
  );
};

export default FooterBottom;
