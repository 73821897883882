import React, { useContext } from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import AppContext from "../../../AppContext";
import { myProductsData } from "../../Content/utilsCardsData";

const MyProducts = () => {
  const { toggleSubmenuSublevel, isMobile } = useContext(AppContext);
  return (
    <>
      <div className="spring">
        <div className="submenu__list">
          <a className="back-button" onClick={toggleSubmenuSublevel}>
            <Icon Source={Svg.ArrowLeft} />
          </a>
          {isMobile && <h4 className="submenu__list--title heading">My Products</h4>}
          <ul>
            {myProductsData.map((product, index) => (
              <li key={index} className="submenu__list--item">
                <h4 className="heading">{product.productType}</h4>
                <p>{product.productName}</p>
                <div className="submenu__list--image">
                  <img src={product.productImage} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default MyProducts;
