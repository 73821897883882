import { Svg } from "../../ws10";

export const broadbandData = [
  {
    id: "Vodafone Pro Xtra",
    name: "Vodafone Pro Xtra",
    description: "Features of Pro Broadband plan, plus:",
    icon: Svg.Broadband,
    price: { value: 36, unit: "pounds" },
    recurringChargePeriodType: "month",
    buttonText: "Choose this plan",
    category: "broadband",
    options: [
      { icon: Svg.Tv, text: "Apple TV 4K on us" },
      { icon: Svg.Call, text: "Unlimited Anytime calls to UK phones" },
      { icon: Svg.Payg, text: "Dedicated WiFi Xperts on call 24/7" },
      { icon: Svg.Tick, text: "12 months free Norton 360 Premium" }
    ],
    modalDetails: {
      description: ["Vodafone Pro Xtra"],
      overview: [
        "Good for browsing, catching up on social media and streaming music.",
        "Save at least 3€ when you buy this plan and broadband together",
        "Unilimited Data",
        "Unlimited Germany minutes",
        "Unlimited Germany texts",
        "3-month free trial of Secure Net",
        "51 roam-free destinations",
        "Speed: Maximum download of 10 Mpbs"
      ],
      included: ["Included"],
      "additional charges": ["Additional charges"],
      "what to know": ["What to know"],
      "about speed": ["About speed"],
      checkAvailabilityButtonText: "Check Availability",
      cancelButtonText: "Cancel",
      chooseThisBroadbandButtonText: "Choose This Broadband",
      chooseAnotherBroadbandButtonText: "Choose Another Broadband"
    },
    availablePostalCodes: ["100", "101"]
  },
  {
    id: "Vodafone Pro Broadband",
    name: "Vodafone Pro Broadband",
    description: "Features of Broadband plan, plus:",
    icon: Svg.Broadband,
    price: { value: 28, unit: "pounds" },
    recurringChargePeriodType: "month",
    category: "broadband",
    options: [
      { icon: Svg.Network, text: "Automatic 4G Broadband Back-U" },
      { icon: Svg.Tick, text: "Super WiFi Booster for reliable Wifi" },
      { icon: Svg.Payg, text: "Dedicated WiFi Xperts on call 24/7" },
      { icon: Svg.Call, text: "12 months free Norton 360 Premium" }
    ],
    highlighted: true,
    highlightedText: "Recommended",
    modalDetails: {
      description: ["Vodafone Pro Broadband"],
      overview: [
        "Good for browsing, catching up on social media and streaming music.",
        "Save at least 3€ when you buy this plan and broadband together",
        "Unilimited Data",
        "Unlimited Germany texts",
        "3-month free trial of Secure Net",
        "51 roam-free destinations",
        "Speed: Maximum download of 10 Mpbs"
      ],
      included: ["Included"],
      "additional charges": ["Additional charges"],
      "what to know": ["What to know"],
      "about speed": ["About speed"],
      checkAvailabilityButtonText: "Check Availability",
      cancelButtonText: "Cancel",
      chooseThisBroadbandButtonText: "Choose This Broadband",
      chooseAnotherBroadbandButtonText: "Choose Another Broadband"
    },
    availablePostalCodes: ["100", "102"],
    buttonText: "Choose this plan"
  },
  {
    id: "Vodafone Broadband",
    name: "Vodafone Broadband",
    description: "Includes:",
    icon: Svg.Broadband,
    price: { value: 20, unit: "pounds" },
    recurringChargePeriodType: "month",
    category: "broadband",
    options: [
      { icon: Svg.Network, text: "Intelligent WiFi Hub" },
      { icon: Svg.Tick, text: "No upfront fees" },
      { icon: Svg.Payg, text: "Vodafone Together discount" },
      { icon: Svg.Call, text: "Landline included" }
    ],
    modalDetails: {
      description: "Vodafone Broadband",
      overview: [
        "Good for browsing, catching up on social media and streaming music.",
        "Save at least 3€ when you buy this plan and broadband together",
        "Unilimited Data",
        "Unlimited Germany minutes",
        "Unlimited Germany texts",
        "3-month free trial of Secure Net",
        "51 roam-free destinations",
        "Speed: Maximum download of 10 Mpbs"
      ],
      included: ["Included"],
      "additional charges": ["Additional charges"],
      "what to know": ["What to know"],
      "about speed": ["About speed"],
      checkAvailabilityButtonText: "Check Availability",
      cancelButtonText: "Cancel",
      chooseThisBroadbandButtonText: "Choose This Broadband",
      chooseAnotherBroadbandButtonText: "Choose Another Broadband"
    },
    availablePostalCodes: ["100", "103"],
    buttonText: "Choose this plan"
  }
];

export const overlayBroadbandTabs = ["Overview", "Included", "What to know"];
