import classnames from "classnames";
import invariant from "invariant";
import React from "react";
import { Svg } from "../../../ws10";
import styles from "./Form.module.scss";
import FormField from "./_FormField.web";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

const FormInput = ({
  id,
  label,
  type = "text",
  name,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isErrorLabel = false,
  isCaptioned = false,
  isShaded = false,
  isLight = false,
  isRound = false,
  isIcon,
  iconSource,
  onFocus,
  onChange,
  onBlur,
  maxLength,
  readOnly = false
}) => {
  invariant(
    ["text", "number", "password", "email", "date"].includes(type),
    `FormInput: Type can only be "text", "password", "number" and "email" types. ${type} given`
  );
  return (
    <FormField
      {...{
        id,
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden,
        isErrorLabel
      }}>
      <span className={styles["form--input"]}>
        <input
          {...{
            id,
            type,
            name,
            placeholder,
            value,
            onFocus,
            onChange,
            onBlur,
            maxLength,
            readOnly
          }}
          className={classnames("form__input", {
            "form__input--shaded": isShaded,
            "form__input--caption": isCaptioned,
            "form__input--light": isLight,
            "form__input--disabled": isDisabled,
            "form__input--round": isRound
          })}
          required={isRequired}
          disabled={isDisabled}
        />
        {isIcon && <Icon Source={iconSource || Svg.Search} className={styles["form__input__icon"]} />}
      </span>
    </FormField>
  );
};

FormInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isErrorHidden: PropTypes.bool,
  isErrorLabel: PropTypes.bool,
  isCaptioned: PropTypes.bool,
  isShaded: PropTypes.bool,
  isLight: PropTypes.bool,
  isRound: PropTypes.bool,
  isIcon: PropTypes.bool,
  iconSource: PropTypes.object,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  id: PropTypes.string
};

export default FormInput;
