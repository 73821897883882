import React, { useContext } from "react";
import ColumnsMenu from "./ColumnsMenu";
import TopNavigationBar from "./_TopNavigationBar";
import NavigationMenu from "./_NavigationMenu";
import Submenu from "../Submenu";
import Search from "../ws10/Search";
import { Svg } from "../../../ws10";

import AppContext from "../../../AppContext";

function Menu() {
  const {
    toggleSubmenu,
    isSubmenuDisplayed,
    isSearchDisplayed,
    isMyVodafoneMenuDisplayed,
    isShopMenuDisplayed,
    isHelpMenuDisplayed
  } = useContext(AppContext);

  const navIcon = {
    iconClass: "#icon-my-vodafone",
    link: {
      title: "Vodafone",
      url: "/",
      target: "_blank"
    }
  };
  const homepageChildren = [
    {
      title: "Shop",
      //url: "/shop"
      id: "home-page-shop",
      classSubmenuOpen: isShopMenuDisplayed
    },
    {
      title: "Help",
      //url: "#"
      id: "home-page-help",
      classSubmenuOpen: isHelpMenuDisplayed
    },
    {
      title: "My Vodafone",
      //openSubmenu: toggleSubmenu,
      id: "home-page-my-vodafone",
      classSubmenuOpen: isMyVodafoneMenuDisplayed
    },
    {
      title: "Menu",
      //openSubmenu: toggleSubmenu,
      id: "home-page-menu",
      classSubmenuOpen: isSubmenuDisplayed
    }
  ];

  const consumerLink = { title: "Personal", url: "/" };
  const businessLink = { title: "Business", url: "https://www.vodafone.co.uk/business" };
  const extraSiteLinks = [
    { title: "Store Locator", url: "/", icon: Svg.VodafoneStore },
    { title: "Network Checker", url: "/", icon: Svg.Network }
  ];

  const configurationLink = {
    url: "/"
  };
  const homepage = "/"; //FIXME

  return (
    <>
      <ColumnsMenu>
        <TopNavigationBar consumerLink={consumerLink} businessLink={businessLink} extraSiteLinks={extraSiteLinks} />
        <NavigationMenu
          navIcon={navIcon}
          isSticky={false}
          homepage={homepage}
          links={homepageChildren}
          configurationLink={configurationLink}
          openMenuHandler={toggleSubmenu}>
          {/* TODO: put here the links */}
        </NavigationMenu>
        <Submenu />
      </ColumnsMenu>
      {isSearchDisplayed && <Search />}
    </>
  );
}

export default Menu;
