import React from "react";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

function TopLink({ link, active }) {
  return (
    <li className="navigation__item hide--md hide--sm">
      <a
        href={link.url}
        className={`navigation__link navigation__link--slim navigation__site ${
          active ? "navigation__site--active" : ""
        }`}>
        {link.icon && <Icon Source={link.icon} className="topLink-icon" />}
        {link.title}
      </a>
    </li>
  );
}

TopLink.propTypes = {
  link: PropTypes.exact({
    url: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.object
  }),
  active: PropTypes.bool
};

export default TopLink;
