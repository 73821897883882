import { sentenceCase } from "change-case";
import classnames from "classnames";
import isEmpty from "lodash.isempty";
import isEqual from "lodash.isequal";
import pluralize from "pluralize";
import React, { useCallback, useEffect, useRef, useState } from "react";
import uuidv4 from "uuid/v4";
import { Heading, Section, Spring, List } from "@dc/react-components";
import Alert from "../Alert/Alert";
import Button from "../Button";
import Link from "../Link";
import { Svg } from "../../../ws10";
import FormDateTimePicker from "./_FormDateTimePicker.web";
import { getFormLabelSelector } from "./_FormField.web";
import FormInput from "./_FormInput.web";
import FormTextArea from "./_FormTextArea.web";
import FormToggle from "./_FormToggle.web";
import FormToggleButton from "./_FormToggleButton.web";
import FormRow from "./_FormRow.web";
import FormSelect from "./_FormSelect.web";
import PropTypes from "prop-types";

const Form = ({ id, errors = {}, submitButtonLabel, isDark = false, children, onSubmit }) => {
  const form = useRef();
  const [errorLabels, setErrorLabels] = useState({});
  useEffect(() => {
    if (form.current && !isEmpty(errors)) {
      form.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [form, errors]);
  useEffect(() => {
    if (form.current) {
      const $form = form.current;
      const nextErrorLabels = Object.entries(errors).reduce((labels, [name]) => {
        const label =
          $form.querySelector(getFormLabelSelector(name)) &&
          $form.querySelector(getFormLabelSelector(name)).textContent;
        if (!label) {
          return labels;
        }
        return {
          ...labels,
          [name]: label
        };
      }, {});
      if (!isEqual(errorLabels, nextErrorLabels)) {
        setErrorLabels(nextErrorLabels);
      }
    }
  }, [form, errors, errorLabels]);
  const handleErrorClick = useCallback(
    (name) => {
      if (form.current) {
        const $form = form.current;
        $form.querySelector(getFormLabelSelector(name)).scrollIntoView({ behavior: "smooth" });
      }
    },
    [form]
  );
  return (
    <form
      {...{ id }}
      // $FlowFixMe: false negative
      ref={form}
      className={classnames("form", { "form--dark": isDark }, "no-gutter--bottom")}
      noValidate
      onSubmit={(e) => {
        if (onSubmit) {
          e.preventDefault();
          onSubmit(e);
        }
      }}>
      {!isEmpty(errorLabels) ? (
        <Section color="wild-sand" isTrailing>
          <Spring>
            <Alert iconSource={Svg.Block} isLight>
              <Heading level={6} component="h3" fontWeight="regular" isLeading>
                {(() => {
                  const count = Object.keys(errorLabels).length;
                  return `Sorry, there ${pluralize("were", count)} ${pluralize("error", count, true)} in the
              following ${pluralize("field", count)}:`;
                })()}
              </Heading>
              <List component="ol">
                {Object.entries(errorLabels).map(([name, label]) => (
                  <List.Item key={uuidv4()}>
                    <Link isBody onClick={() => handleErrorClick(name)}>
                      {sentenceCase(label)}
                    </Link>
                  </List.Item>
                ))}
              </List>
            </Alert>
          </Spring>
        </Section>
      ) : null}
      {children}
      {submitButtonLabel ? (
        <Form.Row>
          <Button type="submit" disabled={isEmpty(errors)}>
            {submitButtonLabel}
          </Button>
        </Form.Row>
      ) : null}
    </form>
  );
};
Form.Row = FormRow;
Form.Input = FormInput;
Form.Select = FormSelect;
Form.TextArea = FormTextArea;
Form.DateTimePicker = FormDateTimePicker;
Form.Toggle = FormToggle;
Form.ToggleButton = FormToggleButton;

Form.propTypes = {
  id: PropTypes.string,
  errors: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  isDark: PropTypes.bool,
  children: PropTypes.node,
  onSubmit: PropTypes.func
};

export default Form;
