import React from "react";
import "../../../ws2/resources/assets/styles/main.scss";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import "./Spinner.scss";

function Spinner() {
  return (
    <div className="spinner">
      <Icon Source={Svg.Sync} size="medium" />
    </div>
  );
}
export default Spinner;
