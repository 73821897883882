import { resetEventsData } from "./utagEventsData";

function sendData(utagData) {
  let data = utagData;
  window.utag_data = { ...data };
  window.utag.link(data);
  resetEventsData();
}

export default function trackActionEvents(eventData) {
  let utagData;

  if (Array.isArray(eventData)) {
    eventData.forEach((utagObject) => {
      for (const value of Object.values(utagObject)) {
        utagData = value;
        sendData(utagData);
      }
    });
  } else {
    utagData = eventData;
    sendData(utagData);
  }
}
