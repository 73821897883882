import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@dc/react-components";

const SocialIcon = ({ url, iconSource, socialNetwork }) => (
  <a href={url}>
    <Icon Source={iconSource} className="icon--fill icon--thin" />
    <span className="visually-hidden">{socialNetwork}</span>
  </a>
);

SocialIcon.propTypes = {
  url: PropTypes.string.isRequired,
  iconSource: PropTypes.object.isRequired,
  socialNetwork: PropTypes.string.isRequired
};

export default SocialIcon;
