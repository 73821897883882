const SEPARATOR = "//**//";
// timestamp for cache validation
const CACHE_INTERVAL = 300000; // 5 minutes

// endpoints to cache
const endpointsWhiteList = [
  "balanceAndProducts",
  "dashboard",
  "history",
  "balance",
  "bill",
  "eligibleProducts",
  "productOfferingDetails"
];

const isURLInWhiteList = (url) => endpointsWhiteList.filter((endpoint) => url.includes(endpoint)).length > 0;

const clearStorage = (key) => localStorage.getItem(key) && localStorage.removeItem(key);

function store(key, value) {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`;
  localStorage.setItem(key, finalValue);
}

function getValue(key) {
  return localStorage.getItem(key);
}

function isCachedDateValid(key, value) {
  const values = value.split(SEPARATOR);
  const timestamp = Number(values[1]);

  const date = new Date(timestamp);
  if (date.toString() === "Invalid Date") {
    return false;
  }

  if (Date.now() - date.getTime() < CACHE_INTERVAL) {
    return {
      value: values[0]
    };
  } else {
    clearStorage(key);
  }
}

function isValid(key) {
  const value = getValue(key);

  if (value === null || !isCachedDateValid(key, value)) {
    return {
      isValid: false
    };
  } else if (isCachedDateValid(key, value)) {
    return {
      isValid: true,
      value: isCachedDateValid(key, value).value
    };
  }
}

export const cache = {
  store,
  isValid,
  isURLInWhiteList
};
