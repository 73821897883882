import { Svg } from "../../../ws10";

const mobileTabletsProducts = [
  {
    category: "Pay monthly phones",
    icon: Svg.ChevronRight
  },
  {
    category: "Pay as you go phones",
    icon: Svg.ChevronRight
  },
  {
    category: "Ipads and tablets",
    icon: Svg.ChevronRight
  },
  {
    category: "Nearly new deals",
    icon: Svg.ChevronRight
  },
  {
    category: "Mobile broadband",
    icon: Svg.ChevronRight
  },
  {
    category: "Compare phones",
    icon: Svg.ChevronRight
  }
];

const SimProducts = [
  {
    category: "Subcategory 1",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 2",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 3",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 4",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 5",
    icon: Svg.ChevronRight
  }
];

const TvProducts = [
  {
    category: "Subcategory 1",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 2",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 3",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 4",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 5",
    icon: Svg.ChevronRight
  }
];

const WeareablesProducts = [
  {
    category: "Subcategory 1",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 2",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 3",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 4",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 5",
    icon: Svg.ChevronRight
  }
];

const BundlesOffersProducts = [
  {
    category: "Subcategory 1",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 2",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 3",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 4",
    icon: Svg.ChevronRight
  },
  {
    category: "Subcategory 5",
    icon: Svg.ChevronRight
  }
];

export { mobileTabletsProducts, SimProducts, TvProducts, WeareablesProducts, BundlesOffersProducts };
