import axios from "axios";
import React from "react";

function TestPage() {
  const testApi = async () => {
    fetch('https://portal-proxy-eu9-dev.dxl-nonprod.aws.cps.vodafone.com/serverEndPoint/setCookie?vid_client=JOE', { credentials: "include"}).then((data) => {console.log(data)}).catch((data) => {console.warn(data)})
   
  };
  return (
    <div>
      <button
        style={{
          margin: "200px"
        }}
        onClick={() => testApi()}>
        Test API
      </button>
    </div>
  );
}

export default TestPage;
