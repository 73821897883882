import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import Datetime from "react-datetime";
import { Svg } from "../../../ws10";
import { Icon } from "@dc/react-components";
import "./DateTimePicker.scss";

const DateTimePicker = ({ isDisabled, icon, inputProps = {}, isValidDate, ...props }) => {
  const isValidDateCb = useCallback(
    (current) => {
      let valid = true;
      if (isValidDate) {
        valid = valid && isValidDate(current);
      }
      if (inputProps.min) {
        valid = valid && current.isSameOrAfter(inputProps.min);
      }
      if (inputProps.max) {
        valid = valid && current.isSameOrBefore(inputProps.max);
      }
      return valid;
    },
    [inputProps, isValidDate]
  );
  return (
    <div className={"grid"}>
      <Datetime
        {...props}
        isValidDate={isValidDateCb}
        inputProps={{
          ...inputProps,
          className: classnames("form__input", {
            "form__input--disabled": isDisabled
          }),
          disabled: isDisabled
        }}
      />
      <Icon
        className={classnames("DayPicker--icon grid__item grid__item--push", {
          "DayPicker--icon-disabled": isDisabled
        })}
        source={icon}
      />
    </div>
  );
};

DateTimePicker.defaultProps = {
  icon: Svg.Calendar,
  dateFormat: "DD/MM/YYYY",
  isDisabled: false,
  inputProps: {
    readOnly: true,
    placeholder: "dd/mm/yyyy"
  },
  timeFormat: false,
  closeOnSelect: true
};

DateTimePicker.propTypes = {
  dateFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isDisabled: PropTypes.bool,
  icon: PropTypes.instanceOf(Svg),
  inputProps: PropTypes.shape({
    disabled: PropTypes.oneOf(["disabled", undefined]),
    readOnly: PropTypes.bool,
    max: PropTypes.oneOfType([PropTypes.number, Date]),
    min: PropTypes.oneOfType([PropTypes.number, Date]),
    required: PropTypes.oneOf(["required", undefined, true, false]),
    placeholder: PropTypes.string
  }),
  closeOnSelect: PropTypes.bool,
  timeFormat: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  isValidDate: PropTypes.func
};

export default DateTimePicker;
