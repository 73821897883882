import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ToggleSwitch from "../ToggleSwitch";

function CardDcl({
  title,
  children,
  hasGutter,
  bgcolor = "white",
  alignment = "left",
  isContentNarrow,
  edit,
  type,
  hidden,
  handleVisibilityToggle,
  bgImg,
  onClickHandler, 
  height, 
  submenuBanner
}) {
  return (
    <div
      className={classnames(
        "card",
        !submenuBanner && `card__color--${bgcolor}`,
        `card__align--${alignment}`,
        {
          "card--gutter": hasGutter,
          "card--hide": hidden
        }, 
        submenuBanner &&"card__submenuBanner",
        !edit && onClickHandler ? "clickable" : ""
      )}
      onClick={() => {
        if (!edit && onClickHandler) onClickHandler();
      }}
      style={{
        backgroundImage: bgImg && `url(${bgImg}`,
        height: height
      }}>
      {/* START toggle  */}
      {edit && (
        <ToggleSwitch
          value={!hidden}
          offLabel="hidden"
          onLabel="visible"
          onChange={() => {
            handleVisibilityToggle(type);
          }}
        />
      )}
      {/* <p>{}</p> */}
      {/* END toggle  */}
      {title && <div className="card__header">{title}</div>}
      <div
        className={classnames("card__content", {
          "card__content--narrow": isContentNarrow
        })}>
        {children}
      </div>
    </div>
  );
}

CardDcl.propTypes = {
  title: PropTypes.string,
  hasGutter: PropTypes.bool,
  bgcolor: PropTypes.string,
  alignment: PropTypes.string,
  isContentNarrow: PropTypes.bool,
  edit: PropTypes.bool,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  handleVisibilityToggle: PropTypes.func,
  onClickHandler: PropTypes.func,
  children: PropTypes.node
};

export default CardDcl;
