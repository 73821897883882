import React from "react";
import PropTypes from "prop-types";
import { Svg } from "../../../ws10";
import Form from "../Form";
import { Icon } from "@dc/react-components";

// TODO: unit test the logic in this file

/**
 * Function called onfocus od the inputs of the form
 * It adds the necessary css classes to the elements
 * @param {*} e - event fired containing the target element of that event
 */
function onFocusInput(e) {
  const inputElement = e.target;
  const label = inputElement.parentElement.parentElement;
  const floatingLabel = inputElement.parentElement.previousSibling;
  if (inputElement.value === "") {
    label.classList.add("on-focus");
  }
  floatingLabel.classList.add("focus");
}

/**
 * Function called onfocus od the inputs of the form
 * It removes the necessary css classes to the elements
 * @param {*} e - event fired containing the target element of that event
 */
function handleInputFocusOut(e) {
  const input = e.target;
  const label = input.parentElement.parentElement;
  const floatingLabel = input.parentElement.previousSibling;
  if (input.value === "") {
    label.classList.remove("on-focus");
  }
  floatingLabel.classList.remove("focus");
}

function LoginFormPassword({ username, password, isFormFieldOk, displayUserForm, fillPassword, id }) {
  const handleInputPassword = (e) => {
    const password = e.target.value;
    fillPassword(true, password);
  };

  const returnToUserForm = () => {
    fillPassword(false, "");
    displayUserForm();
  };

  const displayHint = !isFormFieldOk ? "It seems your credentials are incorrect." : null;

  return (
    <Form.Row spaceTop="leading">
      <div className="submenu__back-step">
        <button type="button" onClick={returnToUserForm}>
          <Icon Source={Svg.ArrowLeft} />
        </button>
        <span>{username}</span>
      </div>
      <Form.Input
        label="Password"
        name="password"
        onChange={(event) => handleInputPassword(event)}
        onFocus={(event) => onFocusInput(event)}
        onBlur={(event) => handleInputFocusOut(event)}
        value={password}
        type="password"
        isRequired
        isIcon={!isFormFieldOk}
        isErrorLabel={!isFormFieldOk}
        iconSource={Svg.Warning}
        iconPosition="right"
        hint={displayHint}
        id={"sign-in-password-field-" + id}
      />
    </Form.Row>
  );
}

LoginFormPassword.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  displayUserForm: PropTypes.func.isRequired,
  fillPassword: PropTypes.func.isRequired,
  isFormFieldOk: PropTypes.bool.isRequired,
  id: PropTypes.string
};

export default LoginFormPassword;
