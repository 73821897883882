import { Svg } from "../../../ws10";

const menuItems = [
  {
    name: "Shop",
    id: "menu-item-shop"
  },
  {
    name: "Help",
    id: "menu-item-help"
  },
  {
    name: "My Vodafone",
    id: "menu-item-my-vodafone"
  }
];

const swithToBusinessItem = {
  name: "Switch to business",
  url: "#",
  icon: Svg.ChevronRight
};

const bottomItems = [
  {
    name: "Store Locator",
    url: "#",
    icon: Svg.VodafoneStore
  },
  {
    name: "Network Checker",
    url: "#",
    icon: Svg.Network
  }
];

export { menuItems, swithToBusinessItem, bottomItems };
