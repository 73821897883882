import classnames from "classnames";
import React from "react";
import styles from "./Form.module.scss";
import FormField from "./_FormField.web";
import PropTypes from "prop-types";

const FormTextArea = ({
  label,
  name,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  isShaded = false,
  isLight = false,
  isRound = false,
  rows,
  cols,
  onFocus,
  onChange,
  onBlur,
  maxLength,
}) => (
  <FormField
    {...{
      label,
      name,
      hint,
      error,
      isRequired,
      isDisabled,
      isErrorHidden,
    }}>
    <span className={styles["form--input"]}>
      <textarea
        {...{
          name,
          placeholder,
          value,
          onFocus,
          onChange,
          onBlur,
          maxLength,
          rows,
          cols,
        }}
        className={classnames("form__input", styles["text-area"], {
          "form__input--shaded": isShaded,
          "form__input--caption": isCaptioned,
          "form__input--light": isLight,
          "form__input--disabled": isDisabled,
          "form__input--round": isRound,
          [styles["text-area-cols"]]: cols,
        })}
        required={isRequired}
        disabled={isDisabled}
      />
    </span>
  </FormField>
);

FormTextArea.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isCaptioned: PropTypes.bool,
  isShaded: PropTypes.bool,
  isLight: PropTypes.bool,
  isRound: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  isErrorHidden: PropTypes.bool,
};

export default FormTextArea;
