import React, { useContext, useEffect } from "react";
import PlanSection from "./components/_PlanSection";
import AccordionItem from "./components/_AccordionItem";
import Card from "../../Card";
import { Options, ViewDetailsBtn, Checkbox } from "../../_Content";
import { Button } from "@dc/react-components";
import PropTypes from "prop-types";
import AppContext from "../../../../../../AppContext";
import { currencySymbols, productIcons } from "../../../../../../utils";

const PlanCard = ({
  id,
  planIcon,
  planName,
  price,
  recurringChargePeriodType,
  productCategory,
  description,
  bundledProductOffering,
  options,
  hasCompare = true,
  isHighlighted,
  highlightLabel,
  buttonText,
  updateSelectedPlanAndToggleOverlay,
  includeModal
}) => {
  const { addProductToBasket } = useContext(AppContext);
  const subtitle = (
    <>
      {price.value + currencySymbols[price.unit]}
      <span>/</span>
      <span>{recurringChargePeriodType}</span>
    </>
  );

  const thisProduct = {
    id: id,
    name: planName,
    price: price.value,
    currency: price.unit,
    recurringChargePeriodType: recurringChargePeriodType,
    category: productCategory,
    description: description,
    options: bundledProductOffering,
    url: "",
    color: "",
    colorHex: "",
    storage: "",
    quantity: 1,
    icon: productIcons[productCategory],
    actions: true
  };

  return (
    <Card
      className={`card__item--plan ${hasCompare ? "card__plan--compare" : ""} ${
        isHighlighted ? "plan__highlight" : ""
      }`}>
      {isHighlighted && <div className="highlight">{highlightLabel}</div>}
      <AccordionItem icon={planIcon} title={planName} subtitle={subtitle}>
        <PlanSection className="plan__section--light-grey plan__options">
          {options && productCategory == "plans" ? (
            <Options options={options} />
          ) : (
            <div>
              {description}
              <Options options={options} />
            </div>
          )}
        </PlanSection>
        <PlanSection className="plan__details">
          {includeModal && (
            <ViewDetailsBtn
              label="View details"
              updateSelectedPlanAndToggleOverlay={updateSelectedPlanAndToggleOverlay}
              planName={planName}
            />
          )}
          {buttonText && (
            <Button
              id={"plan-card-action-btn" + id}
              className="card--button secondary"
              onClick={() => thisProduct && addProductToBasket(thisProduct)}>
              {buttonText}
            </Button>
          )}
        </PlanSection>
        {hasCompare && (
          <PlanSection className="plan__section--grey">
            <Checkbox label="Compare" id={id} />
          </PlanSection>
        )}
      </AccordionItem>
    </Card>
  );
};
PlanCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  planIcon: PropTypes.object,
  planName: PropTypes.string,
  price: PropTypes.object,
  recurringChargePeriodType: PropTypes.string,
  productCategory: PropTypes.string,
  description: PropTypes.string,
  bundledProductOffering: PropTypes.object,
  servicesTitle: PropTypes.string,
  services: PropTypes.array,
  options: PropTypes.array,
  hasCompare: PropTypes.bool,
  isHighlighted: PropTypes.bool,
  highlightLabel: PropTypes.string,
  buttonText: PropTypes.string,
  updateSelectedPlanAndToggleOverlay: PropTypes.func,
  removeProduct: PropTypes.func,
  includeModal: PropTypes.bool
};
export default PlanCard;
