import React from "react";
import PropTypes from "prop-types";
import { Icon, List } from "@dc/react-components";
import { Svg } from "../../../ws10";

const Navigation = ({ iconSrc, onClick, title }) => {
  return (
    <List.Item>
      <a onClick={onClick} className="dashboard__link">
        <span className="dashboard__link--text">
          <Icon Source={iconSrc} />
          <span className="dashboard__link--title">{title}</span>
        </span>
        <span className="dashboard__link--arrow">
          <Icon Source={Svg.ChevronRight} />
        </span>
      </a>
    </List.Item>
  );
};

Navigation.propTypes = {
  iconSrc: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default Navigation;
