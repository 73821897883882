import React from 'react';
import PropTypes from 'prop-types';


const FooterBottomItem = ({ url, linkText }) => { 
    const link = <a href={url}>{linkText}</a>
    
    return (
        <>
            <li className="list__item">
                {link}
            </li> 
        </>
    );
};

FooterBottomItem.propTypes = {
    index: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
}

export default FooterBottomItem;
