import { setApiRequest, setApiResponse } from "./src/page/utagNetworkData";
import setStartingPageData from "./src/page/setStartingPageData";
import trackView from "./src/page/trackView";
import setEventData from "./src/events/setEventData";

let configs;

function trackingInit(config) {
  configs = config;
}

function getTrackingConfigs() {
  return configs;
}

export {
  trackingInit,
  getTrackingConfigs,
  setStartingPageData,
  trackView,
  setApiRequest,
  setApiResponse,
  setEventData
};
