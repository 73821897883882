import React from "react";
import PropTypes from "prop-types";

import "./Card.scss";

const Card = ({ isHorizontal, isColumn, backgroundImage, hasBackground, children, className }) => {
  const getClasses = () => {
    let classes = "card card__item has--shadow";

    if (isColumn) {
      classes += " card__item--column";
    }

    if (isHorizontal) {
      classes += " card__horizontal";
    }

    if (hasBackground) {
      classes += " has--background";
    }

    // TODO move this do dashboard card impl
    // if (isDashboardCard) {
    //   classes += " card__item--dashboard";
    // }

    // TODO Placeholder - Card Product details
    // if (imageSrc) {
    //   classes += " has--image";
    // }

    if (className) {
      classes += ` ${className}`;
    }

    return classes;
  };

  return (
    <>
      <div className={getClasses()}>
        {backgroundImage && <BackgroundImage backgroundImage={backgroundImage} />}
        {children}
      </div>
    </>
  );
};

export default Card;

export const BackgroundImage = ({ backgroundImage }) => (
  <div className="card__image--background">
    <img src={backgroundImage} />
  </div>
);

// TODO improve this component
export const CardActionArea = ({ children, onClickHandler }) => <span onClickHandler={onClickHandler}>{children}</span>;

Card.propTypes = {
  isColumn: PropTypes.bool,
  backgroundImage: PropTypes.string,
  isHorizontal: PropTypes.bool,
  hasBackground: PropTypes.bool,
  onClickHandler: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

BackgroundImage.propTypes = {
  backgroundImage: PropTypes.string,
};

CardActionArea.propTypes = {
  children: PropTypes.node,
  onClickHandler: PropTypes.func,
};
