import classnames from "classnames";
import * as React from "react";
import PropTypes from "prop-types";

const FormRow = ({ children, isInline, spaceTop = "default" }) => {
  return (
    <div
      className={classnames("form__row", {
        [`form__row--${spaceTop}`]: spaceTop !== "default",
        "form__row--inline": isInline,
      })}>
      {children}
    </div>
  );
};

FormRow.propTypes = {
  children: PropTypes.node,
  isInline: PropTypes.bool,
  spaceTop: PropTypes.string,
};

export default FormRow;
