import classNames from "classnames";
import pick from "lodash.pick";
import React from "react";
import styles from "./Form.module.scss";
import PropTypes from "prop-types";

const FormToggleButton = ({
  accent = "grey",
  label,
  value = "",
  isRequired = false,
  isChecked = false,
  isDisabled = false,
  darkBackground = false,
  onChange,
  ...props
}) => {
  const nullable = !isRequired && isChecked;
  return (
    <label
      className={classNames(styles["form__toggle-button"], {
        [styles["form__toggle-button--grey"]]: accent === "grey",
        [styles["form__toggle-button--green"]]: accent === "green",
        [styles["form__toggle-button--red"]]: accent === "red",
        [styles["form__toggle-button--checked"]]: isChecked,
        [styles["form__toggle-button--dark"]]: darkBackground,
      })}>
      {label}
      <input
        {...pick(props, ["name", "onFocus", "onBlur"])}
        {...(nullable ? { onClick: onChange } : { onChange })}
        className="visually-hidden"
        type="radio"
        value={nullable ? "" : value}
        required={isRequired}
        checked={isChecked}
        disabled={isDisabled}
      />
    </label>
  );
};

FormToggleButton.propTypes = {
  accent: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  darkBackground: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FormToggleButton;
