import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index.js";
import { PROXY_HEADERS, HEADERS, capitalize, serializeUrlParams } from "../utils/Http.utils.js";

/*
  Fetch the balance information from DXL
*/
export async function getBalanceData(userId, userData, userType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  if (userData) {
    const apiUrl = configs.balance;
    const baseParams = `relatedParty[?(@.role=='${userType}')].id`;
    const params = {};
    params[baseParams] = userId;

    const serviceName = "getBalance";
    const requestData = {
      endpoint: apiUrl,
      method: "GET",
      request: {
        userId,
        userType
      }
    };
    configs.setApiRequest(serviceName, requestData);

    try {
      const response = await axios.get(apiUrl, {
        params,
        paramsSerializer: () => serializeUrlParams(params),
        headers: PROXY_HEADERS.headers,
        withCredentials: PROXY_HEADERS.withCredentials
      });

      if (response) {
        configs.setApiResponse(serviceName, response.data);
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  }
}

/*
  Fetch the billing history information from DXL
*/
export async function getHistoryData(userId, userData, userType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  if (userData) {
    const apiUrl = configs.history;
    const baseParams = `relatedParty[?(@.role=='${userType}')].id`;
    const params = {};
    params[baseParams] = userId;

    const serviceName = "getHistory";
    const requestData = {
      endpoint: apiUrl,
      method: "GET",
      request: {
        userId,
        userType
      }
    };
    configs.setApiRequest(serviceName, requestData);

    try {
      const response = await axios.get(apiUrl, {
        params,
        paramsSerializer: () => serializeUrlParams(params),
        headers: PROXY_HEADERS.headers,
        withCredentials: PROXY_HEADERS.withCredentials
      });

      if (response) {
        configs.setApiResponse(serviceName, response.data);
        return response.data;
      }
    } catch (error) {
      const data = _get(error, "response.data");
      if (data) {
        throw new Error(JSON.stringify(data));
      }
      throw error;
    }
  }
}

/*
  Fetch a particular bill/invoice information from DXL
*/
export async function getBillData(userId, billId, userData, userType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  if (userData) {
    const apiUrl = configs.bill;
    const billParams = `bill.id`;
    const baseParams = `relatedParty[?(@.role=='${userType}')].id`;
    const params = {};
    params[billParams] = billId;
    params[baseParams] = userId;

    const serviceName = "getBillData";
    const requestData = {
      endpoint: apiUrl,
      method: "GET",
      request: {
        userId,
        userType,
        billId
      }
    };
    configs.setApiRequest(serviceName, requestData);

    HEADERS.headers.Authorization = capitalize(userData.token_type) + " " + userData.token;

    try {
      const response = await axios.get(apiUrl, {
        params,
        paramsSerializer: () => serializeUrlParams(params),
        headers: PROXY_HEADERS.headers,
        withCredentials: PROXY_HEADERS.withCredentials
      });

      if (response) {
        configs.setApiResponse(serviceName, response.data);
        return response.data;
      }
    } catch (error) {
      const data = _get(error, "response.data");
      if (data) {
        throw new Error(JSON.stringify(data));
      }
      throw error;
    }
  }
}

export async function getOutOfBundleAmount(userId, userData, userType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  if (userData) {
    const apiUrl = configs.billAnalysis;
    const baseParams = `relatedParty[?(@.role=='${userType}')].id`;
    const params = {};
    params[baseParams] = userId;

    const serviceName = "getOutOfBundleAmount";
    const requestData = {
      endpoint: apiUrl,
      method: "GET",
      request: {
        userId,
        userType
      }
    };
    configs.setApiRequest(serviceName, requestData);

    try {
      const response = await axios.get(apiUrl, {
        params,
        paramsSerializer: () => serializeUrlParams(params),
        headers: PROXY_HEADERS.headers,
        withCredentials: PROXY_HEADERS.withCredentials
      });

      if (response) {
        configs.setApiResponse(serviceName, response.data);
        return response.data;
      }
    } catch (error) {
      const data = _get(error, "response.data");
      if (data) {
        throw new Error(JSON.stringify(data));
      }
      throw error;
    }
  }
}
