import classNames from "classnames";
import * as React from "react";
import { Svg } from "../../../ws10";
import styles from "./Form.module.scss";
import Alert from "../Alert/Alert";
import PropTypes from "prop-types";

const FORM_FIELD_ERROR_CLASS_NAME_PREFIX = "form__field--error";
const FORM_LABEL_CLASS_NAME = "form__label";

export const getFormLabelSelector = (name) => `.${FORM_FIELD_ERROR_CLASS_NAME_PREFIX}${name} .${FORM_LABEL_CLASS_NAME}`;

const FormField = ({
  type = "field",
  label,
  hint,
  error = "",
  isRequired = false,
  children,
  isDisabled = false,
  isErrorHidden = false,
  isErrorLabel = false,
}) => {
  const FieldComponent = type === "group" ? "fieldset" : "div";
  const LabelComponent = type === "group" ? "legend" : "label";
  const message = (
    <Alert iconSource={Svg.Block} isLight hasArrow>
      {error}
    </Alert>
  );
  return (
    <div>
      {error.length && !isDisabled ? isErrorHidden ? <div className="visually-hidden">{message}</div> : message : null}
      <FieldComponent>
        <LabelComponent>
          {label ? (
            <span
              className={classNames(
                FORM_LABEL_CLASS_NAME,
                {
                  "form__label--required": isRequired,
                },
                {
                  "form__label--error": isErrorLabel,
                },
                styles["form__label"]
              )}>
              {label}
            </span>
          ) : null}

          {children}
        </LabelComponent>
        {hint ? (
          <div
            className={classNames("form__label--error small", {
              "form__input--disabled": isDisabled,
            })}>
            {hint}
          </div>
        ) : null}
      </FieldComponent>
    </div>
  );
};

FormField.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isErrorHidden: PropTypes.bool,
  isErrorLabel: PropTypes.bool,
};

export default FormField;
