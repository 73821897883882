export class DxlError extends Error {
  constructor(error) {
    super(error);
    this.status = error.response.status;
  }
}

export class NetworkOrInternetConnectionError extends Error {
  constructor(error) {
    super(error);
    this.status = error.message;
  }
}

export class TimeoutError extends Error {
  constructor() {
    super();
    this.status = 408;
  }
}
