import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import AppContext from "../../../AppContext";
import PropTypes from "prop-types";

const Shop = ({ id }) => {
  const {
    isMobile,
    toggleSubmenu,
    toggleSubmenuSublevel,
    isMobilesAndTabletsDisplayed,
    isBroadbandDisplayed,
    isSimOnlyDisplayed,
    isTVDisplayed,
    isWearablesDisplayed,
    isBundlesAndOffersDisplayed
  } = useContext(AppContext);

  const history = useHistory();

  const links = [
    {
      label: "Mobiles and Tablets",
      isActive: isMobilesAndTabletsDisplayed
    },
    // {
    //   label: "Broadband",
    //   isActive: isBroadbandDisplayed
    // },
    {
      label: "Sim Only",
      isActive: isSimOnlyDisplayed
    },
    {
      label: "TV",
      isActive: isTVDisplayed
    },
    {
      label: "Wearables",
      isActive: isWearablesDisplayed
    },
    {
      label: "Bundles and Offers",
      isActive: isBundlesAndOffersDisplayed
    }
  ];

  const closeSubmenuAndGotToShop = () => {
    toggleSubmenu(false);
    history.push("/shop");
  };

  return (
    <ul className="submenu__content--list">
      {isMobile && (
        <>
          <a className="back-button" onClick={() => toggleSubmenu(true)}>
            <Icon Source={Svg.ArrowLeft} />
          </a>
        </>
      )}
      <a className="submenu__link--red" onClick={() => closeSubmenuAndGotToShop()} id={"go-to-shop-btn-" + id}>
        Go to Shop
      </a>
      {links.map((link, i) => (
        <li key={i} className={`${link.isActive ? "active" : ""}`}>
          <a onClick={() => toggleSubmenuSublevel(true, link.label)}>{link.label}</a>
        </li>
      ))}
    </ul>
  );
};

Shop.propTypes = {
  id: PropTypes.string
};

export default Shop;
