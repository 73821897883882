import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Heading, Icon } from "@dc/react-components";
import { Svg } from "../../../../../../../ws10";
import PropTypes from "prop-types";
import AppContext from "../../../../../../../AppContext";

const AccordionItem = ({ icon, title, subtitle, children }) => {
  const { isMobile } = useContext(AppContext);
  const [isExpanded, setExpanded] = useState(false);

  const { pathname } = useLocation();

  function toggleAccordion() {
    setExpanded(!isExpanded);
  }

  return (
    <>
      <div className="plan__header" onClick={toggleAccordion}>
        {icon && <Icon className="icon--mid" Source={icon} />}
        <Heading level={4} component="h3" fontWeight="bold">
          {title}
          <div className="plan__header--price">{subtitle}</div>
        </Heading>
        {pathname === "/checkout" &&
          (isExpanded ? (
            <Icon Source={Svg.ChevronUp} className="icon--accordion" />
          ) : (
            <Icon Source={Svg.ChevronDown} className="icon--accordion" />
          ))}
        {pathname !== "/checkout" &&
          isMobile &&
          (isExpanded ? (
            <Icon Source={Svg.ChevronUp} className="icon--accordion" />
          ) : (
            <Icon Source={Svg.ChevronDown} className="icon--accordion" />
          ))}
      </div>
      {pathname === "/checkout" && isExpanded && <div className="plan__content">{children}</div>}
      {pathname !== "/checkout" && <div className="plan__content">{(isExpanded || !isMobile) && children}</div>}
    </>
  );
};
AccordionItem.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.object,
  children: PropTypes.array
};
export default AccordionItem;
