import React from "react";
import { setEventData } from "@dc/utag-tracking";
import { getCurrentTime } from "../../../../utils";
import PropTypes from "prop-types";

const UsernameIcon = ({ usernameInitials, handleLogout, isLoggedIn }) => {
  // TODO refactor setClickData to allow reuse
  const setClickData = (label) => {
    return {
      event_name: "ui_interaction",
      event_action: "OnClick",
      event_category: "Button",
      event_label: label,
      journey_type: "Menu",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      page_time_on: getCurrentTime(),
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  return (
    <li className="navigation__item navigation__item--right navigation__hide navigation__item--user-icon">
      <div className="navigation__link navigation__link--icon navigation__menu-arrow">
        <div className="user-icon icon--small" id="user-is-logged-in">
          {usernameInitials}
        </div>
      </div>
      <div className="navigation navigation--tertiary-user">
        <ul className="navigation__list">
          <li className="navigation__item ">
            <button
              type="button"
              className="navigation__link navigation__link--secondary navigation__logout"
              onClick={() => setEventData(handleLogout, setClickData("Logout"))}>
              Logout
            </button>
          </li>
        </ul>
      </div>
    </li>
  );
};

UsernameIcon.propTypes = {
  usernameInitials: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};

export default UsernameIcon;
