import trackActionEvents from "./trackActionEvents";
import { pendingEventData, addEventToPending, resetPendingEventData, resetEventsData } from "./utagEventsData";
import checkIfUtagIsReady from "../checkUtag";

let isRecheckingForUtag = false;

function sendDataWhenUtagIsFinallyReady() {
  const interval = 10000;
  if (pendingEventData.length > 0) {
    isRecheckingForUtag = true;
    if (checkIfUtagIsReady()) {
      trackActionEvents(pendingEventData);
      resetPendingEventData();
      isRecheckingForUtag = false;
    } else {
      setTimeout(sendDataWhenUtagIsFinallyReady, interval);
    }
  }
}

export default function trackEvent(actionData) {
  if (checkIfUtagIsReady()) {
    if (pendingEventData.length > 0) {
      isRecheckingForUtag = false;
      trackActionEvents(actionData);
      resetPendingEventData();
    } else {
      trackActionEvents(actionData);
    }
  } else {
    if (isRecheckingForUtag) {
      addEventToPending();
    } else {
      addEventToPending();
      resetEventsData();
      sendDataWhenUtagIsFinallyReady();
    }
  }
}
