import viewTrackEvent from "./viewTrackEvent";
import {
  getPageData,
  getNetworkData,
  getPendingUtagNetworkData,
  addPageAndNetworkDataToPending,
  resetNetworkData,
  resetPendingUtagNetworkData
} from "./utagNetworkData";
import checkIfUtagIsReady from "../checkUtag";

let isRecheckingForUtag = false;
const pendingUtagNetworkData = getPendingUtagNetworkData();

function sendDataWhenUtagIsFinallyReady() {
  const interval = 10000;
  if (pendingUtagNetworkData.length > 0) {
    isRecheckingForUtag = true;
    if (checkIfUtagIsReady()) {
      viewTrackEvent(pendingUtagNetworkData);
      resetPendingUtagNetworkData();
      isRecheckingForUtag = false;
    } else {
      setTimeout(sendDataWhenUtagIsFinallyReady, interval);
    }
  }
}

export default function trackView() {
  let finalData;
  const pageData = getPageData();
  const network = getNetworkData();
  if (checkIfUtagIsReady()) {
    if (pendingUtagNetworkData.length > 0) {
      isRecheckingForUtag = false;
      finalData = { ...pageData, network };
      viewTrackEvent(pendingUtagNetworkData);
      viewTrackEvent(finalData);
      resetPendingUtagNetworkData();
    } else {
      finalData = { ...pageData, network };
      viewTrackEvent(finalData);
    }
  } else {
    if (isRecheckingForUtag) {
      addPageAndNetworkDataToPending();
      resetNetworkData();
    } else {
      addPageAndNetworkDataToPending();
      resetNetworkData();
      sendDataWhenUtagIsFinallyReady();
    }
  }
}
