const st = require('./st.json');
const development = require('./development.json');
const sit = require('./staging.json');

let localConfig;

if (process.env.REACT_APP_ENV === 'staging') {
  localConfig = sit;
} else if (process.env.REACT_APP_ENV === 'st') {
  localConfig = st;
} else {
  localConfig = development;
}
const config = localConfig;

console.log(`APP ENV:${process.env.REACT_APP_ENV}`);
export default config;
