import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index";
import { HEADERS_APIX_OAUTH, toUrlEncoded } from "../utils/Http.utils";
import { isPassword, isEmail } from "./loginUtils";

const LOGIN_ENDPOINT = "/login";

/**
 * Private method
 * Given the username and password, it builds the formdata for the authentication API
 * @param {String} username - User name of the given user
 * @param {String} password - Passsword of the given user
 */
function getBody(username, password) {
  const body = {
    grant_type: "password",
    username,
    password
  };
  return toUrlEncoded(body);
}

function getUserName(result) {
  if (result?.data?.user_info?.name) {
    return result.data.user_info.name;
  } else {
    return undefined;
  }
}

function getToken(result) {
  if (result?.data?.token_exchange) {
    const accessToken = result.data.token_exchange.find((token) => token.access_token);

    if (accessToken) {
      return {
        accessToken: accessToken.access_token,
        tokenType: accessToken.token_type,
        tokenExpiresAt: accessToken.expires_at
      };
    }
  } else {
    return undefined;
  }
}

function getHashedId(result) {
  if (result?.data?.user_info?.userAssets) {
    const productAsset = result.data.user_info.userAssets.find(
      (asset) => asset["@type"] === "Product" && asset["hashedId"]
    );

    if (productAsset) return productAsset.hashedId;
  }
  return undefined;
}

function getProductdId(result) {
  if (result?.data?.user_info?.userAssets) {
    const productAsset = result.data.user_info.userAssets.find((asset) => asset["@type"] === "Product" && asset["id"]);

    if (productAsset) return productAsset.id;
  }
  return undefined;
}

export function validateForm(username, password) {
  let error = {};
  if (!isPassword(password)) {
    error = {
      ...error,
      ...{
        password: true
      }
    };
  }
  if (!isEmail(username)) {
    error = {
      ...error,
      ...{
        username: true
      }
    };
  }

  return error;
}

export async function login(username, password) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  try {
    HEADERS_APIX_OAUTH.auth.username = configs.proxyUsername;
    HEADERS_APIX_OAUTH.auth.password = configs.proxyPassword;
    const result = await axios.post(
      configs.authEndpoint + LOGIN_ENDPOINT,
      getBody(username, password),
      HEADERS_APIX_OAUTH
    );

    if (result.data) {
      const tokenData = {
        username,
        user_name: getUserName(result),
        token: getToken(result).accessToken,
        token_type: getToken(result).tokenType,
        expiresAt: getToken(result).tokenExpiresAt,
        hashedId: getHashedId(result),
        productId: getProductdId(result)
      };
      if (configs.storage) configs.storage.setItem("accessToken", JSON.stringify(tokenData));
    }

    return result;
  } catch (err) {
    console.log("Error in login", err);
    const data = _get(err, "response.data");
    throw new Error(JSON.stringify(data));
  }
}
