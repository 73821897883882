import React from "react";
import NavigationIcon from "./_NavigationIcon";
import { Svg } from "../../../../ws10";
import PropTypes from "prop-types";

function SearchIcon({ onClick }) {
  return (
    <li className="navigation__item navigation__item--right navigation__hide" onClick={onClick}>
      <NavigationIcon title={"Search"} svgIcon={Svg.Search} SvgIconSize="small" id={"menu-item-search"} />
    </li>
  );
}

SearchIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SearchIcon;
