import { createContext } from "react";

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

/**
 * Context:
 * - isLoggedIn: boolean
 */

const AppContext = createContext({ username: "John Appleseed", isMobile: isMobileDevice() });

export default AppContext;
