import React, { useContext, useState, useRef, useEffect } from "react";
import Form from "../Form";
import LoginFormButton from "./_LoginFormButton";
import LoginFormPassword from "./_LoginFormPassword";
import LoginFormUser from "./_LoginFormUser";
import { Heading, Spring, Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import VodafoneAppImage from "../../../assets/images/vodafone_app.png";
import "./Login.scss";
import AppContext from "../../../AppContext";
import { authServices } from "@dc/digital-channels-sdk";
import PropTypes from "prop-types";

function Login({ id }) {
  // are the form fields filled
  const [isUsernameFilled, setIsUsernameFilled] = useState(false);
  const [isPasswordFilled, setIsPasswordFilled] = useState(false);

  const [usernameHasErrors] = useState(false);
  const [passwordHasErrors, setPasswordHasErrors] = useState(false);

  // data for testing
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // forms visibility
  const [displayLoginFormUser, setDisplayLoginFormUser] = useState("on");
  const [displayLoginFormPassword, setDisplayLoginFormPassword] = useState("off");

  const { handleLogin, isLoggedIn, handleLogout, isMobile, toggleSubmenu } = useContext(AppContext);
  const usernamePanelRef = useRef(null);
  const passwordPanelRef = useRef(null);
  const [loginFormHeight, setLoginFormHeight] = useState();

  // TODO restore trackview
  /* useEffect(() => {
    if (isSubmenuDisplayed && !isLoggedIn) {
      const eventData = { eventName: "page_view", eventType: "network" };
      setStartingPageData("Menu", "Sign In", eventData);
      trackView();
    }
  }, [isLoggedIn, isSubmenuDisplayed]); */

  useEffect(() => {
    if (usernamePanelRef.current && passwordPanelRef.current) {
      const usernameHeight = usernamePanelRef.current.getBoundingClientRect().height;
      const passwordHeight = passwordPanelRef.current.getBoundingClientRect().height;
      setLoginFormHeight(Math.max(usernameHeight, passwordHeight));
    }
  }, [displayLoginFormUser, displayLoginFormPassword, usernameHasErrors, passwordHasErrors]);

  // set username and password to filled
  const fillUsername = (isFilled, user) => {
    setIsUsernameFilled(isFilled);
    setUsername(user);
  };

  const fillPassword = (isFilled, pass) => {
    setIsPasswordFilled(isFilled);
    if (!isFilled) {
      setPasswordHasErrors(false);
    }
    setPassword(pass);
  };

  // display password form and hide username form
  const displayPasswordForm = () => {
    setDisplayLoginFormUser("off");
    setDisplayLoginFormPassword("on");
  };

  // display user form and hide password form
  const displayUserForm = () => {
    setDisplayLoginFormUser("on");
    setDisplayLoginFormPassword("off");
  };

  //hide both forms
  const submitForm = () => {
    const loginValidationErrors = authServices.validateForm(username, password);
    if (Object.keys(loginValidationErrors).length === 0) {
      authServices
        .login(username, password)
        .then((response) => {
          if (response.data && response.data.user_info && response.data.user_info.name) {
            setPassword("");
            handleLogin(response.data.user_info.name);
            const oneHourInMilliseconds = 3600000;
            setTimeout(function () {
              handleLogout();
              alert("Your session has expired. Please Log in again");
            }, oneHourInMilliseconds);
          } else {
            handleLogin(username);
          }
        })
        .catch((err) => {
          console.error("Error in login service");
          console.error(err);
          setPasswordHasErrors(true);
        });
    } else {
      setPasswordHasErrors(true);
    }
  };

  return (
    <Spring className="login" size="small" style={{ left: isLoggedIn ? "-100%" : null }}>
      {isMobile && (
        <>
          <a className="back-button" onClick={() => toggleSubmenu(true)}>
            <Icon Source={Svg.ArrowLeft} />
          </a>
        </>
      )}
      <Heading level={2} component="h2">
        Sign In
      </Heading>
      <div className="login__form" style={{ height: loginFormHeight + "px" }}>
        <Form>
          <legend className="visually-hidden">My Vodafone Login form</legend>
          <div
            className="login__step"
            ref={usernamePanelRef}
            data-visible={displayLoginFormUser}
            style={{ right: displayLoginFormUser === "off" ? "160%" : null }}>
            <LoginFormUser fillUsername={fillUsername} isFormFieldOk={!usernameHasErrors} id={id} />
            <LoginFormButton
              isFormFieldOk={isUsernameFilled && !usernameHasErrors}
              handleClick={displayPasswordForm}
              currentLoginStep={1}
              id={"sign-in-next-btn-" + id}
            />
          </div>
          <div
            className="login__step"
            ref={passwordPanelRef}
            data-visible={displayLoginFormPassword}
            style={{ transform: displayLoginFormPassword === "off" ? null : "translateX(0%)" }}>
            <LoginFormPassword
              id={id}
              username={username}
              password={password}
              isFormFieldOk={!passwordHasErrors}
              fillPassword={fillPassword}
              displayUserForm={displayUserForm}
            />
            <LoginFormButton
              isFormFieldOk={isPasswordFilled}
              handleClick={submitForm}
              currentLoginStep={2}
              id={"sign-in-submit-btn-" + id}
            />
          </div>
        </Form>
      </div>
      <div className="login__card">
        <img className="login__card--image" src={VodafoneAppImage} alt="" />
      </div>
    </Spring>
  );
}
Login.propTypes = {
  id: PropTypes.string
};
export default Login;
