import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { init } from "@dc/digital-channels-sdk";
import { trackingInit, setApiRequest, setApiResponse } from "@dc/utag-tracking";
import config from "./config";
import { sessionStorage } from "./storage";
import { getUserAuthData } from "./utils";

const environmentVariables = {
  proxyUsername: process.env.REACT_APP_PROXY_USERNAME,
  proxyPassword: process.env.REACT_APP_PROXY_PASSWORD,
  cmsAcessToken: process.env.REACT_APP_CMS_ACCESS_TOKEN
};

init({
  ...config,
  ...environmentVariables,
  storage: sessionStorage,
  setApiRequest,
  setApiResponse
});

trackingInit({
  getUserAuthData
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
