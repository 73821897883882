export const HEADERS_APIX_OAUTH = {
  headers: {
    Authorization: ""
  },
  auth: {
    username: "",
    password: ""
  },
  withCredentials: true
};

export const PROXY_HEADERS = {
  headers: {
    "vf-country-code": "EU"
  },
  withCredentials: true
};

export const HEADERS_APIX = {
  headers: {
    Accept: "application/json",
    vf_ext_bp_id: "3a81cf82-3b8d-470a-948e-da517832e6dy",
    "vf-country-code": "UK",
    "vf-trace-transaction-id": "3a81cf82-3b8d-470a-948e-da517832e6dc",
    "Content-Type": "application/x-www-form-urlencoded",
    "vf-target-stub": true
  },
  auth: {
    username: "",
    password: ""
  },
  withCredentials: true
};

export let HEADERS_ELIGIBLE_PRODUCTS = {
  headers: {
    Authorization: "",
    Accept: "application/json",
    "Content-Type": "application/json",
    "Accept-Language": "en",
    "vf-project": "TARGET_PE",
    "vf-trace-transaction-id": "57072d7e-92d4-11e7-abc4-cec278b6b50a",
    vf_ext_bp_id: "688c911a-92d4-11e7-abc4-cec278b6b50a",
    "vf-country-code": "EU"
  },
  withCredentials: true
};

export function getEligibleProductsHeaders() {
  return HEADERS_ELIGIBLE_PRODUCTS;
}

export function setEligibleProductsAuthenticationHeader(authValue) {
  HEADERS_ELIGIBLE_PRODUCTS.headers.Authorization = authValue;
}

export function setEligibleProductsWithAuthenticationHeader(credentialsValue) {
  HEADERS_ELIGIBLE_PRODUCTS.withCredentials = credentialsValue;
}

export function resetEligibleProductsHeaders() {
  HEADERS_ELIGIBLE_PRODUCTS = {
    headers: {
      Authorization: "",
      Accept: "application/json",
      "Accept-Language": "en",
      "vf-project": "TARGET_PE",
      "vf-trace-transaction-id": "57072d7e-92d4-11e7-abc4-cec278b6b50a",
      vf_ext_bp_id: "688c911a-92d4-11e7-abc4-cec278b6b50a",
      "vf-country-code": "EU"
    },
    withCredentials: true
  };
}

export const HEADERS = {
  headers: {
    Authorization: "",
    "Content-Type": "application/json",
    Accept: "application/json",
    "vf-trace-transaction-id": "3a81cf82-3b8d-470a-948e-da517832e6dc",
    "Accept-Language": "al",
    source: "customerAgent",
    operator: "selfcare"
  },
  withCredentials: true
};

export function toUrlEncoded(obj) {
  return Object.keys(obj)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
    .join("&");
}

export function extendedEncodeURIComponent(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}

export function serializeUrlParams(params) {
  const queryParams = Object.entries(params)
    .map(([key, value]) => {
      return `${extendedEncodeURIComponent(key)}=${extendedEncodeURIComponent(value)}`;
    })
    .join("&");
  return queryParams;
}

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const REQUEST_TOKEN_GRANT_TYPE = "";
export const DEFAULT_CLIENT_ID = "";
export const DEFAULT_SCOPE = "TMF_CUSTOMER_BILL_MANAGEMENT_ALL";
