import trackEvent from "./trackEvent";

export let cartData = {};
export let clickData = {};
export let swipeData = {};
export let searchData = {};

export function setEventTrackingData(eventData) {
  if (eventData.event_name && eventData.event_name.includes("cart")) {
    cartData = eventData;
    trackEvent(cartData);
  }
  if (eventData.event_action === "OnClick") {
    clickData = eventData;
    trackEvent(clickData);
  }
  if (eventData.event_action === "swipe") {
    swipeData = eventData;
    trackEvent(swipeData);
  }
  if (eventData.event_action === "Search") {
    searchData = eventData;
    trackEvent(searchData);
  }
  if (eventData.event_action === "Apply Coupon Code") {
    searchData = eventData;
    trackEvent(searchData);
  }
}

export let pendingEventData = [];

export function addEventToPending() {
  const objectName = "pendingEvent" + (pendingEventData.length + 1);
  const pendingData = {
    [objectName]: {
      ...cartData,
      ...clickData,
      ...swipeData,
      ...searchData
    }
  };
  pendingEventData.push(pendingData);
}

// reset data objects
export function resetClickData() {
  clickData = {};
}

export function resetPendingEventData() {
  pendingEventData = {};
}

export function resetEventsData() {
  cartData = {};
  clickData = {};
  swipeData = {};
  searchData = {};
}
