import React from "react";
import { Spring } from "@dc/react-components";
import Social from "./Social";
import Brand from "./Brand";
import FooterMenu from "./FooterMenu";
import FooterBottom from "./FooterBottom";

function Footer() {
  return (
    <footer className="footer">
      <Spring>
        <div className="footer__container">
          <div className="footer__header">
            <Social />
            <Brand />
          </div>
          <div className="footer__content">
            <FooterMenu />
          </div>
          <FooterBottom />
        </div>
      </Spring>
    </footer>
  );
}

export default Footer;
