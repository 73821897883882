import classnames from "classnames";
import * as React from "react";
import PropTypes from "prop-types";
const Button = ({
  children,
  rank = "primary",
  transparent,
  darkBackground,
  layout = "default",
  inline,
  trailing,
  disabled,
  selected,
  className,
  id,
  onClick,
  isWindowRedirect,
  windowRedirectLocation = "",
  ...props
}) => {
  const windowRedirectOnClick = () => {
    window.location.href = `${window.location.origin}/${windowRedirectLocation}`;
  };

  return (
    <button
      type="button"
      className={classnames(
        "button",
        {
          [`button--${rank}`]: !transparent,
          [`button--${rank}--dark`]: !transparent && darkBackground,
          "button--full-width": layout === "fullWidth",
          "button--square": layout === "square",
          "button--trailing": trailing,
          "button--disabled": disabled,
          "button--confirm": selected,
          "button--inline": inline,
          "button--transparent": transparent
        },
        className
      )}
      onClick={isWindowRedirect ? windowRedirectOnClick : onClick}
      id={id}
      {...props}
      disabled={disabled}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.string,
  transparent: PropTypes.bool,
  darkBackground: PropTypes.bool,
  layout: PropTypes.string,
  inline: PropTypes.bool,
  trailing: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.string,
  isWindowRedirect: PropTypes.bool,
  windowRedirectLocation: PropTypes.string
};

export default Button;
