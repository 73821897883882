import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../../../AppContext";
import PropTypes from "prop-types";

const PrivateRoute = ({ children, ...rest }) => {
  const RouteComponent = () => {
    return isLoggedIn ? children : <Redirect to="/" />;
  };

  const { isLoggedIn } = useContext(AppContext);

  return (
    <Route {...rest}>
      <RouteComponent />
    </Route>
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired
};

export default PrivateRoute;
