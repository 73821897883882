import React, { useContext } from "react";
import { setEventData } from "@dc/utag-tracking";
import { getCurrentTime } from "../../../utils";
import AppContext from "../../../AppContext";
import PropTypes from "prop-types";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";

function _MainLink({ link, icon }) {
  const { toggleSubmenu, isLoggedIn } = useContext(AppContext);

  // On tablet with desktop menu, the user must be able to click once to open the menu
  function handleClick(linkTitle) {
    toggleSubmenu(true, linkTitle);
  }

  function setLinkClass() {
    const baseClass = "navigation__link navigation__link--main";

    if (link.classSubmenuOpen) {
      return `${baseClass} navigation__link--active`;
    }
    return baseClass;
  }

  // TODO refactor setClickData to allow reuse
  const setClickData = () => {
    return {
      event_name: "ui_interaction",
      event_action: "OnClick",
      event_category: "Button",
      event_label: link.title,
      journey_type: "Menu",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      //page_name: "Cart",
      //page_section: "Basket",
      page_time_on: getCurrentTime(),
      //page_title: "Cart",
      //page_type: "Cart view",
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  return (
    <a
      role={"button"}
      id={link.id}
      tabIndex={0}
      onClick={() => setEventData(handleClick(link.title), setClickData())}
      className={setLinkClass()}>
      {icon && <Icon Source={Svg.Menu} className="navigation__icon navigation__toggle" />}
      <span>{link.title}</span>
      <span className="visually-hidden">menu</span>
    </a>
  );
}

_MainLink.propTypes = {
  link: PropTypes.object.isRequired,
  icon: PropTypes.bool
};

export default _MainLink;
