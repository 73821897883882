import React from "react";
import { List } from "@dc/react-components";
import PropTypes from "prop-types";

const GridList = (props) => {
  const { items } = props;
  return (
    <>
      {items.map((el) => (
        <List.Item className="footer__menu--item" key={el.id}>
          <a href={el.url}>{el.itemName}</a>
        </List.Item>
      ))}
    </>
  );
};

GridList.propTypes = {
  items: PropTypes.array
};

export default GridList;
