import React from "react";
import NavigationIcon from "./_NavigationIcon";
import { Svg } from "../../../../ws10";
import PropTypes from "prop-types";

const UserIcon = ({ toggleSubmenu, iconTarget }) => {
  return (
    <li className="navigation__item navigation__item--right navigation__hide">
      <NavigationIcon
        id={"menu-item-user"}
        title={"Navigate to chat"}
        onClick={toggleSubmenu}
        svgIcon={Svg.MyVodafone}
        target={iconTarget}
      />
    </li>
  );
};

UserIcon.propTypes = {
  toggleSubmenu: PropTypes.func.isRequired,
  iconTarget: PropTypes.string
};

export default UserIcon;
