import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index.js";
import { serializeUrlParams } from "../utils/Http.utils.js";

/*
  Fetch the Product Offering Details
*/
export async function getSearchData(query) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  const url = configs.search;

  const baseParams = "q";
  const params = {};
  params[baseParams] = query;

  try {
    const response = await axios.get(url, {
      params,
      paramsSerializer: () => serializeUrlParams(params)
    });

    return response.data;
  } catch (error) {
    const data = _get(error, "response.data");
    if (data) {
      throw new Error(JSON.stringify(data));
    }
    throw error;
  }
}
