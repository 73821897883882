import axios from "axios";
import { DxlError, NetworkOrInternetConnectionError, TimeoutError } from "./errors";
import retryRequest from "./retryRequest";
import { cache } from "../caching/cacheService";

const controller = new AbortController();
const signal = controller.signal;

const REQUEST_TIMEOUT = 5000;

const TIMES_TO_RETRY = 3;
let retryCount = 0;

export default function axiosInterceptors() {
  let isOnline = true;
  window.addEventListener("load", () => {
    navigator.onLine ? (isOnline = true) : (isOnline = false);
    window.addEventListener("online", () => {
      isOnline = true;
    });
    window.addEventListener("offline", () => {
      isOnline = false;
    });
  });

  axios.interceptors.request.use((req) => {
    if (!isOnline) {
      return {
        ...req,
        signal,
        cancelToken: controller.abort()
      };
    } else {
      if (req.method.toLowerCase() === "get") {
        const checkIsValidResponse = cache.isValid(req.url);
        if (checkIsValidResponse.isValid) {
          // Serve cached data
          req.headers.cached = true;
          req.data = JSON.parse(checkIsValidResponse.value);
          return Promise.reject(req);
        }

        return req;
      } else {
        return {
          ...req,
          timeout: REQUEST_TIMEOUT
        };
      }
    }
  });

  axios.interceptors.response.use(
    (res) => {
      // Any status code within the range of 2xx causes this function to trigger
      if (res.config.method.toLowerCase() === "get") {
        if (res.config.url && cache.isURLInWhiteList(res.config.url)) {
          // Store data in cache
          cache.store(res.config.url, JSON.stringify(res.data));
        }
      }
      return res;
    },
    (error) => {
      const { config } = error;
      const ORIGINAL_REQUEST = config;
      retryCount++;
      if (error?.headers?.cached) {
        // Serve cached data
        return Promise.resolve(error);
      }
      if (retryCount < TIMES_TO_RETRY) {
        retryRequest(ORIGINAL_REQUEST, REQUEST_TIMEOUT);
      } else {
        // Any status codes that falls outside the range of 2xx causes this function to trigger
        if (error.message === "canceled" || error.message === "Network Error") {
          throw new NetworkOrInternetConnectionError();
        }
        if (error.response?.status === 408 || error.code === "ECONNABORTED") {
          throw new TimeoutError();
        }
        if (error.response?.status >= 100 && error.response?.status < 200) {
          throw new DxlError(error);
        }
        if (error.response?.status >= 400 && error.response?.status < 600) {
          throw new DxlError(error);
        }
        return Promise.resolve(error);
      }
    }
  );
}
