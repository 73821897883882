import * as deepCopy from "lodash/cloneDeep";

// pageData holds page related data
let pageData = {};

export function setPageData(data) {
  pageData = data;
}

export function getPageData() {
  return pageData;
}

// networkData holds API related data
let network = {};

export function setApiRequest(serviceName, request) {
  network[serviceName] = request;
}

export function setApiResponse(serviceName, response) {
  network[serviceName] = { ...network[serviceName], response };
}

export function getNetworkData() {
  return network;
}

let pendingUtagNetworkData = [];

// store data that was not sent to Tealium as utag.js was not available
export function addPageAndNetworkDataToPending() {
  const objectName = "pendingData" + (pendingUtagNetworkData.length + 1);
  const pendingData = {
    [objectName]: {
      ...pageData,
      network
    }
  };
  pendingUtagNetworkData.push(pendingData);
}

export function getPendingUtagNetworkData() {
  return pendingUtagNetworkData;
}

// reset data objects
const emptyObject = {};

export function resetNetworkData() {
  network = {};
}

export function resetPendingUtagNetworkData() {
  pendingUtagNetworkData = {};
}

export function resetUtagNetworkData() {
  pageData = {};
  network = {};
  resetPendingUtagNetworkData();
}
