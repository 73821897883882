import React from "react";
import DateTimePicker from "../DateTimePicker";
import FormField from "./_FormField";
import PropTypes from "prop-types";

const FormInput = ({
  label,
  value = "",
  name,
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  onFocus,
  onChange,
  onBlur,
  ...props
}) => (
  <FormField
    {...{
      label,
      name,
      hint,
      error,
      isRequired,
      isDisabled,
      isErrorHidden,
    }}>
    <DateTimePicker
      {...props}
      {...{ name, value, onFocus, onChange, onBlur }}
      required={isRequired}
      isDisabled={isDisabled}
      onChange={(moment) => {
        onChange(moment.format("DD/MM/YYYY"));
      }}
    />
  </FormField>
);

FormInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isErrorHidden: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default FormInput;
