import React from "react";
import { Svg } from "../../../ws10";
import { Icon } from "@dc/react-components";
import "./Alert.scss";
import PropTypes from "prop-types";

export default function Alert({ errorMessage }) {
  return (
    <div className="alert-box">
      <div className="alert-box__header">
        <Icon Source={Svg.Warning} />
      </div>
      <div className="alert-box__content">{errorMessage}</div>
    </div>
  );
}

Alert.propTypes = {
  errorMessage: PropTypes.string
};
