import initAxiosInterceptors from "./src/axios-interceptors";
import { DxlError, NetworkOrInternetConnectionError, TimeoutError } from "./src/axios-interceptors/errors";
import { login, validateForm } from "./src/auth/login";
import { logout } from "./src/auth/logout";
import { getBalanceAndProductsData } from "./src/balance-and-products/BalanceAndProductsDxlService";
import { getEligibleProducts } from "./src/eligible-products/EligibleProductsDxlService";
import { getProductOfferingDetails } from "./src/product-offering-details/ProductOfferingDetailsDxlService";
import {
  getBalanceData,
  getHistoryData,
  getBillData,
  getOutOfBundleAmount
} from "./src/bill-analysis/BillAnalysisDxlService";
import { getSearchData } from "./src/search/search-service";

initAxiosInterceptors();

export * from "./src/index";
export const authServices = {
  login,
  logout,
  validateForm
};
export const productSelectorServices = {
  eligibleProducts: getEligibleProducts,
  productDetails: getProductOfferingDetails
};
export const billServices = {
  balance: getBalanceData,
  history: getHistoryData,
  bill: getBillData,
  outOfBundleAmount: getOutOfBundleAmount
};
export const balanceAndProductsServices = getBalanceAndProductsData;
export const dxlSdkErrors = {
  dxlError: DxlError,
  networkInternetConnection: NetworkOrInternetConnectionError,
  requestTimeout: TimeoutError
};
export const searchService = getSearchData;
