import React from "react";
import "./CardGroup.scss";

const CardGroup = ({
  orientation,
  numberOfItems,
  aditionalnumberOfItems,
  children,
  hasScroll,
  noScrollMobile,
  alignCenter
}) => {
  //TODO improve error coverage
  // if (numberOfItems > 3) {
  //   console.log("Number of grids must be between 1 and 3");
  //   return null;
  // }
  if (orientation === "simpleGrid") {
    return (
      <div
        className={`cards cards__wrapper cards__grid cards__grid--${numberOfItems} ${alignCenter ? "cards__grid--center" : ""
          }`}>
        {children}
      </div>
    );
  }

  if (orientation === "twoColumnsTwoRowsGrid") {
    return (
      <div
        className={`cards cards__wrapper cards__grid cards__grid--${numberOfItems} ${hasScroll ? "has--scroll" : ""} ${noScrollMobile ? "no-scroll--mobile" : ""
          }`}>
        {React.cloneElement(children[0], { isColumn: true })}

        <div className="cards__rows">
          {children[1]}
          {children[2]}
        </div>
      </div>
    );
  }
  if (orientation === "threeColumnsTwoRowsGrid") {
    const [firstCard, ...cards] = children;
    return (
      <div
        className={`cards cards__wrapper cards__grid cards__grid--${numberOfItems} ${hasScroll ? "has--scroll" : ""}`}>
        {React.cloneElement(firstCard, { isColumn: true })}

        <div className={`cards__wrap cards__grid cards__grid--${aditionalnumberOfItems}`}>{cards}</div>
      </div>
    );
  }
};

CardGroup.propTypes = {};

export default CardGroup;
