import React, { useContext } from "react";
import { useHistory } from "react-router";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../../ws10";
import AppContext from "../../../../AppContext";
import PropTypes from "prop-types";

const ProductsSublevel = ({ category, products, shopUrl, submenuPageName = "See All" }) => {
  const { toggleSubmenu, toggleSubmenuSublevel, isMobile } = useContext(AppContext);

  const history = useHistory();

  const closeSubmenuAndGotToShop = () => {
    toggleSubmenuSublevel(false, category);
    toggleSubmenu(false);
    history.push(`/shop/${shopUrl}`);
  };

  return (
    <ul className="submenu__content--sublist">
      <a className="back-button" onClick={() => toggleSubmenuSublevel(false, category)}>
        <Icon Source={Svg.ArrowLeft} />
      </a>
      {isMobile && <div className="submenu__page-name">{category}</div>}

      <a className="submenu__link--red" onClick={() => closeSubmenuAndGotToShop()}>
        {submenuPageName}
      </a>
      {products.map((product, i) => (
        <li key={i}>
          {product.category ? (
            <>
              {product.category}
              <Icon Source={product.icon} />
            </>
          ) : (
            product
          )}
        </li>
      ))}
    </ul>
  );
};

ProductsSublevel.propTypes = {
  category: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  shopUrl: PropTypes.string.isRequired,
  submenuPageName: PropTypes.string
};

export default ProductsSublevel;
