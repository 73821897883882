import React from "react";
import SocialIcon from "./SocialIcon";
import { List } from "@dc/react-components";
import { Svg } from "../../../ws10";

// data for testing purposes only
const socialNetworkData = [
  {
    url: "#",
    socialNetwork: "Facebook",
    iconSource: Svg.SocialFacebook
  },
  {
    url: "#",
    socialNetwork: "Instagram",
    iconSource: Svg.SocialInstagram
  },
  {
    url: "#",
    socialNetwork: "Twitter",
    iconSource: Svg.SocialTwitter
  },
  {
    url: "#",
    socialNetwork: "Youtube",
    iconSource: Svg.SocialYoutube
  }
];

const Social = () => {
  return (
    <div className="social">
      <p className="heading social__title">Social</p>
      <p className="visually-hidden">Follow us</p>
      <List component="ul" isReset>
        {socialNetworkData.map((network, index) => (
          <List.Item className="social__item" key={index}>
            <SocialIcon {...network} />
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default Social;
