import classNames from "classnames";
import * as React from "react";
import uuidv4 from "uuid/v4";
import { Svg } from "../../../ws10";
import FormField from "./_FormField.web";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

// type Props = {
//   name: string,
//   placeholder?: string,
//   options: $ReadOnly<{ [label: string]: string }>,
//   value?: string,
//   isDisabled?: boolean,
//   isCaptioned?: boolean,
//   isLight?: boolean,
//   onFocus?: (event: SyntheticEvent<HTMLInputElement>) => void,
//   onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
//   onBlur?: (event: SyntheticEvent<HTMLInputElement>) => void
// } & FormFieldProps;

const FormSelect = ({
  label,
  name,
  options,
  placeholder,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  isCaptioned = false,
  isLight = false,
  onFocus,
  onChange,
  onBlur
}) => {
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden
      }}>
      <div
        className={classNames("form__input", "form__input--selectable", {
          "form__input--caption": isCaptioned,
          "form__input--light": isLight,
          "form__input--disabled": isDisabled
        })}>
        <select
          {...{ name, value, onFocus, onChange, onBlur }}
          className="form__select"
          defaultValue={value}
          disabled={isDisabled}>
          {placeholder ? <option value="">{placeholder}</option> : null}
          {Object.entries(options).map(([label, option]) => (
            <option key={uuidv4()} value={option}>
              {label}
            </option>
          ))}
        </select>
        <Icon Source={Svg.ChevronDown} className="form__icon" />
      </div>
    </FormField>
  );
};

FormSelect.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isCaptioned: PropTypes.bool,
  onisLightClick: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.func,
  hint: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  isErrorHidden: PropTypes.bool,
  isLight: PropTypes.bool
};

export default FormSelect;
