import React, { useContext } from "react";
import { Icon } from "@dc/react-components";
import AppContext from "../../../AppContext";
import PropTypes from "prop-types";

const MobileMenu = ({ menuItems, swithToBusinessItem, bottomItems }) => {
  const { toggleSubmenu } = useContext(AppContext);

  return (
    <div className="submenu__mobile">
      <span />
      <ul className="submenu__content--list">
        {menuItems.map((item, i) => (
          <li key={i}>
            <a id={item.id} onClick={() => toggleSubmenu(true, item.name)}>
              {item.name}
            </a>
          </li>
        ))}
      </ul>

      <ul className="submenu__bottom--list">
        <li>
          <a href={swithToBusinessItem.url} className="submenu__bottom--link chevron">
            {swithToBusinessItem.name}
            <Icon Source={swithToBusinessItem.icon} />
          </a>
        </li>
        {bottomItems.map((item, i) => (
          <li key={i}>
            <a href={item.url} className={"submenu__bottom--link"}>
              <Icon Source={item.icon} className="topLink-icon" />
              {item.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

MobileMenu.propTypes = {
  menuItems: PropTypes.array.isRequired,
  swithToBusinessItem: PropTypes.object.isRequired,
  bottomItems: PropTypes.array.isRequired
};

export default MobileMenu;
