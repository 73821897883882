import React from "react";
import { useLocation } from "react-router-dom";
import NavigationIcon from "./_NavigationIcon";
import { Svg } from "../../../../ws10";
import "../../../../pages/Cart/Cart.scss";
import PropTypes from "prop-types";

const ShoppingIcon = ({ onClick, productIsInBasketQuantity, clientOrder }) => {
  const url = useLocation();
  const resolveClass = url.pathname === "/cart" ? " navigation__link--active" : "";

  const productsQuantity = productIsInBasketQuantity();
  return (
    <li className="navigation__item navigation__item--cart navigation__item--right navigation__hide">
      <NavigationIcon
        id={"menu-item-cart"}
        title={"Shopping cart"}
        svgIcon={Svg.Shopping}
        onClick={onClick}
        extraClasses={resolveClass}
      />
      {productsQuantity > 0 && !clientOrder ? (
        <span className="cart__icon--label">
          <span>{productsQuantity}</span>
        </span>
      ) : null}
    </li>
  );
};

ShoppingIcon.propTypes = {
  onClick: PropTypes.func,
  productIsInBasketQuantity: PropTypes.func,
  clientOrder: PropTypes.array
};

export default ShoppingIcon;
