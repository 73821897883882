import pick from "lodash.pick";
import React, { Children, cloneElement } from "react";
import styles from "./Form.module.scss";
import FormField from "./_FormField.web";
import PropTypes from "prop-types";

const FormToggle = ({
  accent = "grey",
  label,
  name,
  value = "",
  hint,
  error,
  isRequired = false,
  isDisabled = false,
  isErrorHidden = false,
  darkBackground = false,
  children,
  ...props
}) => {
  return (
    <FormField
      {...{
        label,
        name,
        hint,
        error,
        isRequired,
        isDisabled,
        isErrorHidden,
      }}
      type="group">
      <div className={styles["form__toggle"]}>
        {Children.map(children, (child) =>
          cloneElement(child, {
            ...child.props,
            ...pick(props, ["onChange", "onFocus", "onBlur"]),
            accent,
            name,
            isRequired,
            isDisabled,
            darkBackground,
            isChecked: child.props.value === value,
          })
        )}
      </div>
    </FormField>
  );
};

FormToggle.propTypes = {
  accent: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  darkBackground: PropTypes.bool,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  hint: PropTypes.string,
  error: PropTypes.string,
  isRequired: PropTypes.func,
  isErrorHidden: PropTypes.func,
};

export default FormToggle;
