import React from "react";
import PropTypes from "prop-types";
import { Svg } from "../../../ws10";
import Grid from "../../../components/web/Grid";
import CardData from "../../../components/web/Card/_CardData";
import Card from "../../../components/web/Card/Card";
//import { setEventData } from "@dc/utag-tracking";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true
  //onSwipe: () => setEventData("swipe", "Dashboard", "Usage consumption slider", "event")
};

const UsageConsumptionSlider = ({ cards }) => {
  return <Slider {...sliderSettings}>{cards}</Slider>;
};

function UsageConsumptionCards({ buckets, settings, editMode, handleVisibilityToggle }) {
  const cards = buckets.map((bucket, index) => {
    const visibility = settings[bucket.usageType];
    const display = visibility || editMode;
    if (!display) {
      return null;
    }
    const percentage = (100 * bucket.remainingValueAmount) / bucket.totalValueAmount;
    const cardData = (
      <CardData
        value={bucket.remainingValueName}
        unit={bucket.remainingValueUnits}
        total={bucket.totalValueName}
        percentage={percentage}
      />
    );
    let icon = null;

    // TODO: use an enumerator?
    switch (bucket.usageType) {
      case "data":
        icon = Svg.Data;
        break;
      case "messages":
        icon = Svg.Chat;
        break;
      case "voice":
        icon = Svg.CallLog;
        break;
      default:
        icon = null;
    }
    return (
      <Grid.Item width="1/1" key={index}>
        <Card
          title={bucket.name}
          icon={icon}
          hidden={!settings[bucket.usageType]}
          edit={editMode}
          type={bucket.usageType}
          handleVisibilityToggle={handleVisibilityToggle}>
          {cardData}
        </Card>
      </Grid.Item>
    );
  });
  return <UsageConsumptionSlider cards={cards} />;
}
export default function UsageConsumptionSection({
  buckets,
  settings,
  editMode,
  handleVisibilityToggle,
  gridNumberOfElements
}) {
  return (
    <UsageConsumptionCards
      buckets={buckets}
      settings={settings}
      editMode={editMode}
      handleVisibilityToggle={handleVisibilityToggle}
      gridNumberOfElements={gridNumberOfElements}
    />
  );
}

UsageConsumptionSlider.propTypes = {
  cards: PropTypes.array
};

UsageConsumptionSection.propTypes = {
  buckets: PropTypes.array,
  settings: PropTypes.object,
  editMode: PropTypes.bool,
  handleVisibilityToggle: PropTypes.func,
  gridNumberOfElements: PropTypes.number
};

UsageConsumptionCards.propTypes = {
  buckets: PropTypes.array,
  settings: PropTypes.object,
  editMode: PropTypes.bool,
  handleVisibilityToggle: PropTypes.func,
  gridNumberOfElements: PropTypes.number
};
