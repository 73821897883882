import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index.js";
import {
  getEligibleProductsHeaders,
  setEligibleProductsAuthenticationHeader,
  setEligibleProductsWithAuthenticationHeader,
  resetEligibleProductsHeaders,
  capitalize,
  serializeUrlParams
} from "../utils/Http.utils.js";

/*
  Fetch the Product Offering Details
*/
export async function getProductOfferingDetails(userId, productId, userType) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  const userData = JSON.parse(configs.storage.getItem("accessToken"));

  let apiUrl;
  let generalHeaders;
  let updatedHeaders;

  if (userData) {
    apiUrl = configs.productDetails;
    setEligibleProductsAuthenticationHeader(capitalize(userData.token_type) + " " + userData.token);
    updatedHeaders = getEligibleProductsHeaders();
  } else {
    setEligibleProductsWithAuthenticationHeader(false);
    generalHeaders = getEligibleProductsHeaders();
    apiUrl =
      "https://product-selector-js-eu9-stg.dxl-nonprod.aws.cps.vodafone.com/productSelector/v1/productOfferingDetails";
  }

  const serviceName = "getProductOfferingDetails";
  const requestData = {
    endpoint: apiUrl,
    method: "GET",
    request: {
      userId,
      userType
    }
  };

  configs.setApiRequest(serviceName, requestData);

  const baseParams = `id`;
  const params = {};
  params[baseParams] = productId;

  try {
    const response = await axios.get(apiUrl, {
      params,
      paramsSerializer: () => serializeUrlParams(params),
      headers: updatedHeaders ? updatedHeaders.headers : generalHeaders.headers,
      withCredentials: updatedHeaders ? updatedHeaders.withCredentials : generalHeaders.withCredentials
    });

    if (response) {
      configs.setApiResponse(serviceName, response.data);
      resetEligibleProductsHeaders();
      return response.data;
    }
  } catch (error) {
    const data = _get(error, "response.data");
    if (data) {
      throw new Error(JSON.stringify(data));
    }
    throw error;
  }
}
