//TODO remove images and folder when api retrieves data
import PhoneCall from "../../assets/images/image01.png";
import FamilyImage from "../../assets/images/image02.png";
import Swing from "../../assets/images/image03.png";
import SherlockImage from "../../assets/images/sherlock-series-4-do.jpg";
import ApplePhone from "../../assets/images/apple-phone01.jpg";
import SecurityCamImage from "../../assets/images/security_cam.png";
import SamsungPhone from "../../assets/images/samsung-phone.jpg";
import NetFlixLogo from "../../assets/images/Netflix_icon.svg";
import FluffyCloudHeadphones from "../../assets/images/fluffy-cloud-headphones.png";
import AppleAdapter from "../../assets/images/apple-power-adapter.jpg";
import PowerBank from "../../assets/images/power-bank.jpg";
import { Svg } from "../../ws10";
import { Broadband } from "../../ws10/svg.web";

//TODO remove all once we have data available from api
export const discoverNewsTiles = [
  {
    className: "tiles__box",
    tileInfo: "Enjoy up to 24 months of the best TV and other Box sets",
    imageUrl: SherlockImage,
  },
  {
    className: "tiles__horizontal",
    tileInfo: "Get the incredible iPhone 11 Pro and Save a huge 406€",
    imageUrl: ApplePhone
  },
  {
    className: "tiles__horizontal tiles__background--red",
    tileInfo: "Save 100€ when you upgrade to a new phone on Pay as you go",
    imageUrl: SecurityCamImage
  }
];

export const mostPopularTilesData = [
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "Love the battery live on this phone, amazing!",
    imageClassName: "cards__image cards__image--1/1",
    imageUrl: SamsungPhone,
    cardTitle: "Samsung S10 Blue 64GB",
    cardSubtitle: "60€ monthly 49€ upfront",
    cardButtonText: "Learn more",
    category: "Phones"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl:
      "https://cdn.vodafone.co.uk/en/assets/images/desktop/Samsung_Galaxy_S21+_5G_phantom_black-full-product-front-600.png",
    cardTitle: "Galaxy S21 Ultra 5G",
    cardSubtitle: "Capture life's moments perfectly",
    cardButtonText: "Learn more",
    category: "Phones"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl:
      "https://cdn.vodafone.co.uk/en/assets/images/desktop/Motorola_Moto_g5G_Plus_surfing_blue-full-product-front-600.png",
    cardTitle: "Moto g 5G Plus",
    cardSubtitle: "Ultra-fast 5G speeds",
    cardButtonText: "Learn more",
    category: "Phones"
  },
  {
    cardBackground: "cards__item cards__background--wild-sand",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl:
      "https://cdn.vodafone.co.uk/en/assets/images/desktop/Sony_Xperia_1_II_5G_mirrored_slate-full-product-front-600.png",
    cardTitle: "Sony Xperia 1 II 5G",
    cardSubtitle: "It empowers you to game",
    cardButtonText: "Learn more",
    category: "Phones"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: SamsungPhone,
    cardTitle: "Xiaomi redmi note 7",
    cardSubtitle: "From 249€",
    cardButtonText: "Buy this",
    category: "Phones"
  },
  {
    cardBackground: "cards__item cards__background--wild-sand",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: NetFlixLogo,
    cardTitle: "Enjoy any one of these when purchasing an Unlimited data plan",
    cardSubtitle: "From 149€",
    cardButtonText: "Learn now",
    category: "Plans"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: "https://cdn.vodafone.co.uk/en/assets/images/large/IMG_sim_data_additional_plans.jpg",
    cardTitle: "Damage and breakdown cover plus",
    cardSubtitle: "From 9€",
    cardButtonText: "Learn more",
    category: "Plans"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: "https://cdn.vodafone.co.uk/en/assets/images/large/IMG_sim_data_additional_plans.jpg",
    cardTitle: "Vodafone International Saver 500",
    cardSubtitle: "From 3€",
    cardButtonText: "Learn now",
    category: "Plans"
  },
  {
    cardBackground: "cards__item cards__background--wild-sand",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: "https://cdn.vodafone.co.uk/en/assets/images/medium/IMG_Vodafone_Broadband_Amazon_Offer_2022.jpg",
    cardTitle: "Vodafone Broadband and TV",
    cardSubtitle: "From 39€/month",
    cardButtonText: "Buy this",
    category: "Broadband"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: "https://cdn.vodafone.co.uk/en/assets/images/medium/gigacubeProductImage.jpg",
    cardTitle: "Gigacube",
    cardSubtitle: "From 29€/month",
    cardButtonText: "Buy this",
    category: "Broadband"
  },
  {
    cardBackground: "cards__item cards__background--white",
    cardHeading: "",
    imageClassName: "cards__image cards__image--1/2",
    imageUrl: "https://cdn.vodafone.co.uk/en/assets/images/medium/mobileBroadbandProductImg.jpg",
    cardTitle: "Mobile broadband",
    cardSubtitle: "From 22€/month",
    cardButtonText: "Buy this",
    category: "Broadband"
  }
];

export const offersTilesData = [
  {
    imageSrc: PhoneCall,
    hasImage: "has--image",
    title: "Vodafone International Saver 100",
    description:
      "If you've got friends and family who live abroad, call them from Germany without worrying about the cost.",
    price: "3€ per month",
    buttonText: "Select"
  },
  {
    imageSrc: FamilyImage,
    hasImage: "has--image",
    title: "Vodafone International Saver 500",
    description: "Keep in touch with loved ones around the world using Vodafone International Saver 500",
    price: "7.50€ per month",
    buttonText: "Select"
  },
  {
    imageSrc: Swing,
    hasImage: "has--image",
    title: "100 International Texts",
    description: "Send 100 international texts - and pay as little as 0,05€ an international text",
    price: "5€ per month",
    buttonText: "Select"
  }
];

// Product details page: Related Items
export const SliderProducts = [
  {
    title: "Title",
    image: SecurityCamImage,
    description: "Description",
    price: "59€",
    buttonText: "Buy Now"
  },
  {
    title: "Title",
    image: FluffyCloudHeadphones,
    description: "Description",
    price: "59€",
    buttonText: "Buy Now"
  },
  {
    title: "Title",
    image: AppleAdapter,
    description: "Description",
    price: "59€",
    buttonText: "Buy Now"
  },
  {
    title: "Title",
    image: PowerBank,
    description: "Description",
    price: "59€",
    buttonText: "Buy Now"
  }
];

// Product details page: More details
export const MoreProductDetails = [
  {
    icon: Svg.ScreenSize,
    title: "Screen size",
    description: "Lorem ipsum dolor"
  },
  {
    icon: Svg.ESim,
    title: "Chipset",
    description: "Lorem ipsum dolor"
  },
  {
    icon: Svg.Battery,
    title: "Battery",
    description: "Lorem ipsum dolor"
  },
  {
    icon: Svg.Camera,
    title: "Camera",
    description: "Lorem ipsum dolor"
  }
];

// My Products dashboard data
export const myProductsData = [
  {
    productType: "Broadband",
    productName: "One Kingdon St",
    productImage: SecurityCamImage
  },
  {
    productType: "My phone",
    productName: "+34 670 83 78 50",
    productImage: ApplePhone
  },
  {
    productType: "Lily's phone",
    productName: "+72 670 53 63 32",
    productImage: ApplePhone
  },
  {
    productType: "V-Home",
    productName: "One Kingdon St",
    productImage: SecurityCamImage
  },
  {
    productType: "Chris's phone",
    productName: "+13 465 98 78 09",
    productImage: ApplePhone
  }
];

// Broadband dashboard data
export const broadbandData = [
  {
    id: "Vodafone Pro Xtra",
    name: "Vodafone Pro Xtra",
    description: "Featured of Pro Broadband plan, plus:",
    icon: Svg.Broadband,
    price: {value: 36, unit: "pounds"},
    recurringChargePeriodType: "month",
    category:'broadband',
    options: [
      {icon: Svg.Tv, text: "Apple TV 4K on us"},
      {icon: Svg.Call, text: "Unlimited Anytime calls to UK phones"}
    ],
    buttonText:"Choose this plan",
    highlighted: true,
    highlightedText: "Recommended",
  },
  {
    id: "Vodafone Pro Broadband",
    name: "Vodafone Pro Broadband",
    description: "Features of Broadband plan, plus:",
    icon: Svg.Broadband,
    price: {value: 28, unit: "pounds"},
    recurringChargePeriodType: "month",
    category:'broadband',
    options: [
      {icon: Svg.Network, text: "Automatic 4G Broadband Back-U"},
      {icon: Svg.Tick, text: "Super WiFi Booster for reliable Wifi in every room"},
      {icon: Svg.Payg , text: "Dedicated WiFi Xperts on call 24/7"},
      {icon: Svg.Call, text: "12 months free Norton 360 Premium"}
    ],
    buttonText:"Choose this plan",
    highlighted: true,
    highlightedText: "Recommended",
  },
  {
    id: "Vodafone Broadband",
    name: "Vodafone Broadband",
    description: "Includes:",
    icon: Svg.Broadband,
    price: {value: 20, unit: "pounds"},
    recurringChargePeriodType: "month",
    category:'broadband',
    options: [
      {icon: Svg.Network, text: "Intelligent WiFi Hub"},
      {icon: Svg.Tick, text: "No upfront fees"},
      {icon: Svg.Payg , text: "Vodafone Together discount"},
      {icon: Svg.Call, text: "Landline included"}
    ],
    buttonText:"Choose this plan",
    highlighted: true,
    highlightedText: "Recommended",
  }
];
