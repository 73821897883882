import axios from "axios";
import { get as _get } from "lodash";
import { getConfigs } from "../index.js";

import {
  getEligibleProductsHeaders,
  setEligibleProductsAuthenticationHeader,
  setEligibleProductsWithAuthenticationHeader,
  resetEligibleProductsHeaders,
  capitalize,
  serializeUrlParams
} from "../utils/Http.utils.js";

function getProductsFromCategory(products, productCategory) {
  if (products) {
    if (productCategory === "all") {
      return products;
    } else {
      const tabProducts = products.productOffering.filter((product) => product.category[0].name === productCategory);
      const productIds = tabProducts.map((product) => product.id);
      const tabProductsSpecifications = products.productSpecification.filter((product) =>
        productIds.includes(product.id)
      );

      const activeTabLinksProducts = {
        ...products,
        productOffering: tabProducts,
        productSpecification: tabProductsSpecifications
      };
      return activeTabLinksProducts;
    }
  }
}

/*
Fetch the eligible products
*/
export async function getEligibleProducts(userId, category, userType, count = 25, offset = 0) {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  const userData = JSON.parse(configs.storage.getItem("accessToken"));

  let apiUrl;
  let generalHeaders;
  let updatedHeaders;

  if (userData) {
    setEligibleProductsAuthenticationHeader(capitalize(userData.token_type) + " " + userData.token);
    updatedHeaders = getEligibleProductsHeaders();
    apiUrl = configs.eligibleProducts;
  } else {
    setEligibleProductsWithAuthenticationHeader(false);
    generalHeaders = getEligibleProductsHeaders();
    apiUrl = "https://product-selector-js-eu9-stg.dxl-nonprod.aws.cps.vodafone.com/productSelector/v1/eligibleProducts";
  }

  const serviceName = "getEligibleProducts";
  const requestData = {
    endpoint: apiUrl,
    method: "GET",
    request: {
      userId,
      userType
    }
  };

  configs.setApiRequest(serviceName, requestData);

  let response;
  try {
    if (userData) {
      const baseParams = `id`;
      const params = {};
      params[baseParams] = userId;

      response = await axios.get(apiUrl, {
        params,
        paramsSerializer: () => serializeUrlParams(params),
        headers: updatedHeaders.headers,
        withCredentials: updatedHeaders.withCredentials
      });
    } else {
      response = await axios.get(apiUrl, generalHeaders);
    }
  } catch (error) {
    const data = _get(error, "response.data");
    if (data) {
      throw new Error(JSON.stringify(data));
    }
    throw error;
  }

  if (response) {
    const currentShopTabProducts = getProductsFromCategory(response.data, category);
    configs.setApiResponse(serviceName, currentShopTabProducts);
    resetEligibleProductsHeaders();

    return response.data;
  }
}
