import React from "react";
import { Icon } from "@dc/react-components";
import PropTypes from "prop-types";

const PlanSection = ({ className, title, titleIcon, children }) => {
  return (
    <>
      <div className={`plan__section ${className ? className : ""}`}>
        {title && (
          <p>
            {title}
            {titleIcon && <Icon Source={titleIcon} />}
          </p>
        )}
        {children}
      </div>
    </>
  );
};

PlanSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.object,
  children: PropTypes.node
};

export default PlanSection;
