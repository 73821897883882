import React from "react";
import Grid from "../Grid";
import GridItem from "../Grid/_GridItem.web";
import FooterGrid from "./Desktop/FooterGrid";

const gridData = [
  {
    id: 1,
    heading: "Vodafone Group",
    items: [
      {
        id: 1,
        url: "#",
        itemName: "Why choose Vodafone?"
      },
      {
        id: 2,
        url: "#",
        itemName: "About us"
      },
      {
        id: 3,
        url: "#",
        itemName: "News room"
      },
      {
        id: 4,
        url: "#",
        itemName: "Career"
      },
      {
        id: 5,
        url: "#",
        itemName: "Sustainable development"
      },
      {
        id: 6,
        url: "#",
        itemName: "Business ethics"
      },
      {
        id: 7,
        url: "#",
        itemName: "Vodafone fundation"
      }
    ]
  },
  {
    id: 2,
    heading: "Shop",
    items: [
      {
        id: 1,
        url: "#",
        itemName: "Mobiles & Tablets"
      },
      {
        id: 2,
        url: "/broadband",
        itemName: "Broadband"
      },
      {
        id: 3,
        url: "#",
        itemName: "SIM only"
      },
      {
        id: 4,
        url: "#",
        itemName: "Vodafone TV"
      },
      {
        id: 5,
        url: "#",
        itemName: "Wearables"
      }
    ]
  },
  {
    id: 3,
    heading: "Popular",
    items: [
      {
        id: 1,
        url: "#",
        itemName: "Popular help topic"
      },
      {
        id: 2,
        url: "#",
        itemName: "Popular product"
      },
      {
        id: 3,
        url: "#",
        itemName: "Popular service"
      },
      {
        id: 4,
        url: "#",
        itemName: "Popular bundles"
      }
    ]
  },
  {
    id: 4,
    heading: "Help and support",
    items: [
      {
        id: 1,
        url: "#",
        itemName: "Help with your device"
      },
      {
        id: 2,
        url: "#",
        itemName: "Help with your account"
      },
      {
        id: 3,
        url: "#",
        itemName: "Find a store"
      },
      {
        id: 4,
        url: "#",
        itemName: "Accessibility"
      },
      {
        id: 5,
        url: "#",
        itemName: "Vodafone Community"
      },
      {
        id: 6,
        url: "#",
        itemName: "All help topics"
      },
      {
        id: 7,
        url: "#",
        itemName: "Contact us"
      }
    ]
  }
];

const FooterMenu = () => {
  return (
    <Grid>
      <div className="footer__menu">
        {gridData.map((el) => (
          <GridItem width="1/4" key={el.id}>
            <FooterGrid {...el} />
          </GridItem>
        ))}
      </div>
    </Grid>
  );
};

export default FooterMenu;
