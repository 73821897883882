import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import "./Breadcrumbs.scss";

function BreadcrumbItem({ label, link, isLast }) {
  if (isLast) {
    return <li className="breadcrumbs__item chevron__text">{label}</li>;
  }

  return (
    <li className="breadcrumbs__item">
      <Link className="chevron__text breadcrumbs__crumb" to={link}>
        {label}
      </Link>
      <span className="chevron__container">
        <Icon Source={Svg.ChevronRight} className="icon--size" />
      </span>
    </li>
  );
}

function MobileBreadcrumb({ path }) {
  if (path.length === 1) {
    return (
      <span className="hide--lg breadcrumbs__list">
        <Link to={path[0].link} className="breadcrumbs__crumb">
          {path[0].label}
        </Link>
        <Icon Source={Svg.ArrowLeft} className="breadcrumbs__chevron" />
      </span>
    );
  }

  const pathItem = path[path.length - 2];
  return (
    <span className="hide--lg breadcrumbs__list">
      <Link to={pathItem.link} className="breadcrumbs__crumb">
        {pathItem.label}
      </Link>
      <Icon Source={Svg.ArrowLeft} className="breadcrumbs__chevron" />
    </span>
  );
}

function Breadcrumbs({ path }) {
  return (
    <nav className="breadcrumbs breadcrumbs--extrude">
      <div className="spring">
        <div className="grid grid--gutter">
          <div className="grid__item grid__item--sm-1/1">
            <ul className="list--reset chevron chevron--inline breadcrumbs--center breadcrumbs__list hide--sm">
              {path.map((dataItem, i) => {
                return <BreadcrumbItem key={i} {...dataItem} isLast={i === path.length - 1} />;
              })}
            </ul>
            <MobileBreadcrumb path={path} />
          </div>
        </div>
      </div>
    </nav>
  );
}

MobileBreadcrumb.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  )
};

Breadcrumbs.propTypes = {
  path: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string
    })
  )
};

BreadcrumbItem.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  isLast: PropTypes.bool.isRequired
};

export default Breadcrumbs;
