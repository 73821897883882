import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router";
import { Svg } from "../../../../ws10";
import { Section, Spring, Heading, Icon } from "@dc/react-components";
import { getCurrentTime } from "../../../../utils";
import searchData from "./SearchData";
import { setEventData } from "@dc/utag-tracking";
import AppContext from "../../../../AppContext";
import PropTypes from "prop-types";

const Search = () => {
  const { isSearchDisplayed, toggleSearch, isLoggedIn } = useContext(AppContext);
  const [searchQuery, setSearchQuery] = useState("");

  const history = useHistory();

  const searchInputRef = useRef(null);

  useEffect(() => {
    autoFocus();
  }, []);

  const autoFocus = () => {
    searchInputRef.current.focus();
  };

  const searchFormHandler = (e) => {
    setSearchQuery(e.target.value);
  };

  const submitSearchFormHandler = () => {
    resetFormHandler();
    toggleSearch();
    setEventData(history.push(`/search?q=${searchQuery}`), setSearchData());
  };

  const resetFormHandler = () => {
    autoFocus();
    setSearchQuery("");
  };

  // TODO refactor tracking system for improved reusability
  const setSearchData = () => {
    return {
      event_action: "Search",
      search_terms: searchQuery,
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      page_time_on: getCurrentTime(),
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  const setClickData = (label) => {
    return {
      event_name: "ui_interaction",
      event_action: "OnClick",
      event_category: "Button",
      event_label: label,
      journey_type: "Menu",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      page_time_on: getCurrentTime(),
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  return (
    <div className="search" data-visible={isSearchDisplayed ? "on" : "off"}>
      <Section>
        <Spring>
          <button className="search--close" onClick={() => setEventData(toggleSearch(), setClickData("Close search"))}>
            <Icon Source={Svg.Close} />
          </button>
          <div className="search__container">
            <Heading level="3" component="h2">
              {searchData.title}
            </Heading>
            <form className="search__form" onSubmit={() => submitSearchFormHandler()}>
              <input
                type={searchData.inputType}
                value={searchQuery}
                placeholder={searchData.inputPlaceholder}
                onChange={searchFormHandler}
                ref={searchInputRef}
              />
              {searchQuery.length > 0 && (
                <button
                  type="reset"
                  onClick={() => setEventData(resetFormHandler(), setClickData("Reset Search form"))}>
                  <Icon Source={searchData.closeIcon} />
                </button>
              )}
              <button
                type="submit"
                onClick={() => setEventData(() => {}, setClickData("Submit form"))}
                disabled={searchQuery.length < 1 && "disabled"}>
                <Icon Source={searchData.searchIcon} />
              </button>
            </form>
          </div>
        </Spring>
      </Section>
    </div>
  );
};

Search.propTypes = {
  isSearchDisplayed: PropTypes.bool,
  toggleSearch: PropTypes.func
};

export default Search;
