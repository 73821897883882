// setter
const setItem = (key, value) => {
  sessionStorage.setItem(key, value);
};
// getter
const getItem = key => {
  return sessionStorage.getItem(key);
};
// remove
const removeItem = key => {
  sessionStorage.removeItem(key);
};
// remove all
const clear = () => {
  sessionStorage.clear();
};

const storage = { setItem, getItem, removeItem, clear };

export default storage;
