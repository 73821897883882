import React, { useContext, useEffect } from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";
import Login from "./_Login";
import "./Submenu.scss";
import Dashboard from "../../../pages/dashboard/Dashboard";
import { setEventData } from "@dc/utag-tracking";
import { getCurrentTime } from "../../../utils";
import AppContext from "../../../AppContext";
import Shop from "./_Shop";
import ProductsSublevel from "./Shop/ProductsSublevel";
import Help from "./_Help";
import MobileMenu from "./_MobileMenu";
import MyProducts from "./_MyProducts";
import Broadband from "./_Broadband";
import { menuItems, swithToBusinessItem, bottomItems } from "../../Content/Submenu/MobileMenu";
import {
  mobileTabletsProducts,
  SimProducts,
  TvProducts,
  WeareablesProducts,
  BundlesOffersProducts
} from "../../Content/Submenu/Products";
import Card from "../Card/Card";
import Background_first from "../../../assets/images/submenu-ads/submenu_ads01.jpg";
import Background_second from "../../../assets/images/submenu-ads/submenu_ads02.jpg";

const firstAdsBanner = (
  <Card submenuBanner={true} bgImg={Background_first} height={"90px"}>
    <p className="banner-text">Discover the new iPhone 11 Pro</p>
  </Card>
);

const secondAdsBanner = (
  <Card submenuBanner={true} bgImg={Background_second} height={"75px"}>
    <p className="banner-text">New iPad Pro</p>
  </Card>
);

const adsBanners = [firstAdsBanner, secondAdsBanner];

// Add an ads banner to a given position
function addBanner(originalItems, position, adsItems) {
  for (let i = 0; i < originalItems.length; i++) {
    for (let j = 0; j < position.length; j++) {
      if (originalItems.indexOf(originalItems[i]) === position[j]) {
        originalItems.splice(position[j], 0, adsItems[j]);
      }
    }
  }
}

function Submenu() {
  const {
    isSubmenuDisplayed,
    toggleSubmenu,
    isSublevelDisplayed,
    isMobile,
    isLoggedIn,
    isMobileMenuContentDisplayed,
    isShopMenuDisplayed,
    isHelpMenuDisplayed,
    isMyVodafoneMenuDisplayed,
    isMobilesAndTabletsDisplayed,
    isBroadbandDisplayed,
    isSimOnlyDisplayed,
    isTVDisplayed,
    isWearablesDisplayed,
    isBundlesAndOffersDisplayed,
    isMyProductsDisplayed
  } = useContext(AppContext);

  useEffect(() => {
    addBanner(mobileTabletsProducts, [1, 3], adsBanners);
    addBanner(SimProducts, [1, 3], adsBanners);
  }, []);

  const setClickData = (label) => {
    return {
      event_name: "ui_interaction",
      event_action: "OnClick",
      event_category: "Button",
      event_label: label,
      journey_type: "Menu",
      page_channel: "MarTech Lab Web",
      page_country: "UK",
      page_domain: window.location.hostname,
      page_language: "EN",
      page_locale: window.navigator.language,
      //page_name: "Cart",
      //page_section: "Basket",
      page_time_on: getCurrentTime(),
      //page_title: "Cart",
      //page_type: "Cart view",
      page_url: window.location.href,
      visitor_auth_token: "",
      visitor_login_status: isLoggedIn ? "logged in" : "logged out",
      visitor_type: isLoggedIn ? "customer" : "prospect"
    };
  };

  const checkIfSublevel2IsDisplayed = () => isShopMenuDisplayed || isHelpMenuDisplayed || isMyVodafoneMenuDisplayed;

  return (
    <div
      className="submenu"
      data-visible={isSubmenuDisplayed ? "on" : "off"}
      data-level2={checkIfSublevel2IsDisplayed() ? "on" : "off"}
      data-level3={isSublevelDisplayed ? "on" : "off"}>
      <div className="submenu__content">
        {isMobileMenuContentDisplayed && (
          <MobileMenu menuItems={menuItems} swithToBusinessItem={swithToBusinessItem} bottomItems={bottomItems} />
        )}
        {isMyVodafoneMenuDisplayed && !isMobile && (
          <>
            <Login id="web" />
            <Dashboard />
          </>
        )}
        {isShopMenuDisplayed && <Shop id="web" />}
        {!isMobile && isHelpMenuDisplayed && <Help />}
      </div>
      <div className="submenu__level-2">
        {isShopMenuDisplayed && <Shop id="mobile" />}
        {isHelpMenuDisplayed && <Help />}
        {isMyVodafoneMenuDisplayed && (
          <>
            <Login id="mobile" />
            <Dashboard />
          </>
        )}
      </div>
      <div className="submenu__level-3">
        {isMobilesAndTabletsDisplayed && (
          <ProductsSublevel category={"Mobiles and Tablets"} products={mobileTabletsProducts} shopUrl="phones" />
        )}
        {/* {isBroadbandDisplayed && (
          <ProductsSublevel category={"Broadband"} products={broadbandProducts} shopUrl="accessories" />
        )} */}
        {isBroadbandDisplayed && <Broadband />}
        {isSimOnlyDisplayed && <ProductsSublevel category={"Sim Only"} products={SimProducts} shopUrl="accessories" />}
        {isTVDisplayed && <ProductsSublevel category={"TV"} products={TvProducts} shopUrl="accessories" />}
        {isWearablesDisplayed && (
          <ProductsSublevel category={"Wearables"} products={WeareablesProducts} shopUrl="accessories" />
        )}
        {isBundlesAndOffersDisplayed && (
          <ProductsSublevel category={"Bundles and Offers"} products={BundlesOffersProducts} shopUrl="offers" />
        )}
        {isMyProductsDisplayed && <MyProducts />}
      </div>
      <button
        id="close-menu-btn"
        className="submenu__close"
        onClick={() => setEventData(toggleSubmenu(false), setClickData("close submenu"))}>
        <Icon Source={Svg.Close} />
      </button>
    </div>
  );
}

export default Submenu;
