import React from "react";
import { Icon } from "@dc/react-components";
import { Svg } from "../../../ws10";

const Brand = () => (
  <div className="footer__brand">
    <a href="https://www.vodafone.com">
      <Icon Source={Svg.VodafoneLogoWhite} isThin isFilled size={"extra-large"} />
      <span className="visually-hidden">Vodafone logo</span>
    </a>
  </div>
);

export default Brand;
