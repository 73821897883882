import axios from "axios";
import { get as _get } from "lodash";
import { HEADERS_APIX_OAUTH } from "../utils/Http.utils";
import { getConfigs } from "../index";

const LOGOUT_ENDPOINT = "/logout";

/**
 * Calls the Logout endpoint
 * @param {String} username - User name of the given user
 * @param {String} password - Passsword of the given user
 */
export async function logout() {
  const configs = getConfigs();
  if (!configs) throw new Error("Service not initialised. Please call 'init'");

  const accessToken = JSON.parse(configs.storage.getItem("accessToken"));

  if (accessToken?.token_type && accessToken?.token) {
    HEADERS_APIX_OAUTH.headers.Authorization = accessToken.token_type + " " + accessToken.token;
    HEADERS_APIX_OAUTH.auth.username = configs.proxyUsername;
    HEADERS_APIX_OAUTH.auth.password = configs.proxyPassword;
  }

  try {
    const response = await axios.post(
      configs.authEndpoint + LOGOUT_ENDPOINT,
      HEADERS_APIX_OAUTH.headers.Authorization,
      HEADERS_APIX_OAUTH
    );

    if (response.status === 200) {
      configs.storage.removeItem("accessToken");
      return response.status;
    }
  } catch (err) {
    console.log("Error in logout", err);
    throw new Error(err);
  }
}
